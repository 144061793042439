.reports-info-table {

  .educational-callouts {

    .splide {
      overflow: hidden;
    }

    .callout-item {
      h3 {
        font-size: 20px;
        font-weight: 900;
        margin-bottom: 20px;
      }

      p {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 0;
      }
    }

    .callout-pagination {
      position: relative;
      padding: 20px 0 0;
      display: flex;

      .callout-pagination-btn {
        background: #D9D9D9;

        &.is-active {
          background: #FD7B56;
          transform: scale(1);
        }
      }
    }

    .pagination-hidden {
      display: none;
    }
  }

  &.plan-details-table {
    margin-top: 64px;
  }

  .card {
    margin-top: 24px;
    padding: 28px 28px 32px 30px;

    .description {
      h6 {
        font-size: 20px;
      }
    }
  }

  .header {
    h6 {
      font-size: 24px;
    }

    p {
      margin-bottom: 0;
      font-size: 16px;
      color: var(--dark-gray, #3D5D6F);
    }
  }

  .reporting-table {
    overflow-x: scroll;
    white-space: nowrap;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: var(--light-blue, #CAE1FF);
      box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }
  }

  .title-column {
    display: inline-table;
    width: 440px;
  }

  .first-column {
    text-align: right;
  }

  .first-column,
  .secondary-column {
    display: inline-table;
    width: 330px;

    &.two-column-group {
      width: 330px;
    }

    &.three-column-group {
      width: 268px;
    }

    &.four-column-group {
      width: 268px;
    }
  }

  .amount {
    color: var(--dark-blue, #0C354B);
    font-size: 16px;
    font-weight: normal;
    text-transform: capitalize;

    &.first-amount {
      font-family: sofiaproblack, sans-serif;
      font-weight: 900;
    }

    &.green {
      color: var(--dark-green, #10B981);
    }
  }

  .year {
    color: var(--mid-gray, #9EAEB7);
  }

  .bullets {
    display: inline-block;
    height: 14px;
    width: 14px;
    flex-shrink: 0;
    border-radius: 2px;
    margin-bottom: -3px;
    margin-right: 8px;

    &.orange {
      background: var(--orange, #F39D2A);
    }

    &.blue {
      background: var(--dark-blue, #0C354B);
    }
  }

  .column-entry {
    background-color: var(--off-white, #F6FAFF);
    margin-bottom: 10px;
    margin-right: 12px;
    padding: 12px;
    min-height: 48px;

    h6.plan-name {
      display: inline;
      font-size: 14px;
      margin-bottom: 0;
    }

    &.title-column-entry {
      margin-right: 0;
    }

    &.bg-dark-gray {
      background-color: var(--light-gray, #E2E8F0);
    }

    &.plan-quote {
      text-align: right;
    }
  }

  .column-title {
    color: var(--dark-gray, #3D5D6F);
    font-size: 14px;
    font-weight: 900;
    padding: 12px 22px;
    text-align: right;
    text-transform: uppercase;
    font-family: sofiaproblack, sans-serif;
  }

  .table-subtitle {
    color: var(--dark-blue, #0C354B);
    display: inline-block;
    font-family: sofiaprobold, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 16px;
    padding: 16px 0px;

    .bullets {
      margin-bottom: -2px;
      margin-left: 12px;
    }
  }

  .table-section {
    margin-bottom: 16px;
    margin-top: 16px;
  }

  &.expandable-card .card {
    .arrow-container {
      margin-right: 12px;
    }

    h6 {
      margin-bottom: 0;
    }
  }

  .table-section-heading {
    .title {
      margin-top: 64px;
      font-size: 24px;
      color: var(--dark-blue, #0C354B);
    }

    .subtitle {
      font-size: 16px;
      color: var(--dark-gray, #3D5D6F);
      margin-bottom: 24px;
    }
  }
}