.chart-description {
  margin-top: 42px;

  &.compare-plan {
    .icon {
      text-align: right;
    }

    .title {
      font-family: sofiaprobold, sans-serif;
      font-size: 24px;
      color: var(--dark-blue, #0C354B);
    }

    .subtitle {
      color: var(--dark-gray, #3D5D6F);
      line-height: 16px;
    }
  }
}
