.coverage-selector-card {
  position: relative;
  z-index: 1;
}

.coverage-selector-row {
  width: 959px;
  max-width: fit-content;
  padding-top: 16px;
  padding-bottom: 16px;
  background: var(--white, #FFFFFF);
  border-radius: 160px;

  .coverage-selector-col {
    @media screen and(max-width: 1170px) {
      flex: 0 0 auto;
      width: 100%;
    }
  }
}

.coverage-selector-option-text {
  width: 105px;
  height: 26px;
  font-family: sofiaprobold;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  text-align: center;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: var(--dark-blue, #0C354B);
  color: var(--mid-gray, #9EAEB7);
}

.coverage-selector-option-text:hover {
  cursor: pointer;
}

.coverage-selector-option-text.text-healthcare-selected,
.text-dental-selected,
.text-vision-selected {
  color: var(--dark-blue, #0C354B);
}

.coverage-selector-current-selection {
  width: 292px;
  height: 72px;
  background: var(--white, #FFFFFF);
  box-sizing: border-box;
  border-radius: 88px;
}

.coverage-selector-current-selection.border-healthcare-selected,
.border-dental-selected,
.border-vision-selected {
  border: 4px solid var(--primary-red, #FD7B56);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.theo-coverage-selector {
  position: relative;
  z-index: 0;
}
