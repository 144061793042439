.white-card {
  background-color: var(--white, #FFFFFF);
  border-radius: 8px;
  border: 1px solid var(--light-gray, #E2E8F0);
  box-shadow: 0 0 1px rgba(15, 23, 42, 0.06), 0 4px 6px -1px rgba(15, 23, 42, 0.1),
    0 2px 4px -1px rgba(15, 23, 42, 0.06);
  box-sizing: border-box;

  h6 {
    margin-bottom: 14px;
  }

  .card-description {
    font-size: 14px;
    padding: 0 7px;
  }
}

.main-dialogue {
  &.page-results,
  &.page-cbr {
    padding-bottom: 100px;

    .card {
      box-shadow: 0px 0px 1px rgba(15, 23, 42, 0.06), 0px 4px 6px rgba(15, 23, 42, 0.1),
        0px 2px 4px rgba(15, 23, 42, 0.06);
      // border: 1px solid var(--light-gray, #E2E8F0);
      box-sizing: border-box;
      border-radius: 8px;
    }
    .comparePlanCard {
      border: 1px solid var(--primary-blue, #68A8FB);
    }
  }
}
