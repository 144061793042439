.accordion {
  .card .card-header{
    position: relative;

    &::after {
      font-family: 'mhmchevrondown', sans-serif;
      position: absolute;
      color: var(--primary-red, #FD7B56);
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      font-size: 32px;
      text-transform: none;
      line-height: 24px;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      display: inline-block;
      content: "\e900";
      right: -16px;
      top: 0;

      @media (min-width: $mediumScreen) and (min-width: $largeScreen){
        right: 0
      }
    }
  }
}
