.bullets {
  height: 15px;
  width: 15px;
  flex-shrink: 0;
  border-radius: 4px;
  margin-right: 8px;

  &.bg-yellow {
    background: var(--yellow, #FECB60);
  }

  &.bg-orange {
    background: var(--orange, #F39D2A);
  }

  &.bg-hyke-dark-blue{
    background: var(--hyke-dark-blue, #3D5D6F);
  }

  &.bg-dark-green{
    background: var(--dark-green, #10B981);
  }

  &.bg-hyke-success-green{
    background: var(--hyke-success-green, #6EE7B7);
  }

  &.bg-hyke-worst-case-red{
    background: var(--hyke-worst-case-red, #F14261);
  }

  &.bg-blue {
    background: var(--dark-blue, #0C354B);
  }

  &.bg-primary-red {
    background: var(--primary-red, #FD7B56);
  }

  &.bg-light-red {
    background: var(--light-red, #FFAB93);
  }

  &.bg-light-blue {
    background: var(--light-blue, #CAE1FF);
  }

  &.bg-medium-blue {
    background: var(--medium-blue, #1D60C1);
  }

  &.bg-primary-blue {
    background: var(--primary-blue, #68A8FB);
  }
}
