.input-number {
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  max-width: 34vw;

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
    margin: auto;
  }

  .question-title {
    margin-top: 0;
    margin-bottom: 25px;
    font-size: 20px;
    text-align: left;
  }

  .question-subtitle {
    line-height: 25px;
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 15px;
  }

  .input-group {
    border: 1px solid var(--light-gray, #E2E8F0);
    border-radius: 5px;
    max-width: 560px;
    margin: 0 auto;
    background: var(--white, #FFFFFF);

    &:focus-within {
      border-color: var(--dark-blue, #0C354B);
    }

    .form-control {
      outline: none;
      box-shadow: none;
      border: none;
      &:focus {
        box-shadow: none;
        border-color: var(--light-gray, #E2E8F0);
      }
      button {
        box-shadow: none;
      }
    }

    .form-control-prefix-suffix {
      max-width: 100%;
    }

    .btn {
      outline: none;
      box-shadow: none;
      border: none;
      &:focus {
        box-shadow: none;
      }
      &.plus {
        border-left: 1px solid var(--light-gray, #E2E8F0);
      }
    }

    .prefix-suffix-row {
      margin-right: 0px;
      margin-left: 0px;
      padding-right: 20px;

      &.prefix-only {
        padding-right: 0px;
      }

      &.suffix-only {
      }

      .prefix-col {
        padding: 0px;
        font-family: sofiaprobold;
        font-style: normal;
        font-weight: 900;
        font-size: 20px;
        line-height: 26px;
        align-items: center;
        font-feature-settings: 'tnum' on, 'lnum' on;
        color: var(--mid-gray, #9EAEB7);
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 1;
      }

      .suffix-col {
        padding: 0px;
        font-family: sofiaprobold;
        font-style: normal;
        font-weight: 900;
        font-size: 20px;
        line-height: 26px;
        align-items: center;
        font-feature-settings: 'tnum' on, 'lnum' on;
        color: var(--mid-gray, #9EAEB7);
      }

      .prefix-no-padding {
        padding-left: 2px;
      }
    }
  }

  .sherpa-form-input {
    max-width: 560px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media screen and (max-width: $largeScreen) {
  .input-number {
    max-width: 80vw;
  }
}
