.option-radio {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 34vw;
  position: relative;

  input[type='radio'] {
    display: none;

    &:not(:checked) ~ .content-wrapper .radio-option .radio {
      background: url('../../img/radio-unselected.svg') no-repeat center left;
      height: 87px;

      @media screen and (max-width: $smallScreen) {
        height: 70px;
      }
    }

    &:checked ~ .content-wrapper .radio-option .radio {
      background: url('../../img/radio-selected.svg') no-repeat center left;
      height: 87px;

      @media screen and (max-width: $smallScreen) {
        height: 65px;
      }
    }

    &:checked ~ .content-wrapper {
      border: 2px solid var(--primary-blue, #68A8FB);
    }
  }

  .content-wrapper {
    background-color: var(--white, #FFFFFF);
    margin-top: 30px;
    min-height: 87px;
    border: 1px solid var(--light-gray, #E2E8F0);
    border-radius: 8px;
    text-align: left;
    padding-left: 22px;
    padding-right: 22px;
    cursor: pointer;
    box-sizing: border-box;
    width: 100%;

    .radio-option {
      display: flex;
      align-items: center;
      align-content: center;

      .radio {
        width: 48px;
        flex-shrink: 0;
      }

      .radio-option-text-wrapper {
        .option-title {
          cursor: pointer;
          padding-left: 10px;
        }
      }
    }

    .option-title {
      font-family: sofiaprobold, serif;
      font-size: 20px;
      color: var(--dark-blue, #0C354B);
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media screen and (max-width: $smallScreen) {
        font-size: 16px;
      }
    }

    @media screen and (max-width: $smallScreen) {
      min-height: 65px;
    }
  }

  .option-content-with-popup.option-radio-default {
    visibility: hidden;
  }

  input[type='radio']:checked
    ~ .option-content-with-popup.option-radio-default {
    visibility: visible;
  }

  .option-radio-default {
    .option-content-with-popup {
      display: none;
    }
    input[type='radio']:checked ~ .option-content-with-popup {
      display: block;
    }
  }

  &.has-follow-up input[type='radio']:checked ~ .content-wrapper {
    border-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .option-content-with-popup {
    margin-top: -6px;
  }

  .display-as-dependency {
    left: 10px;
    top: 100px;
  }
  .btn-edit {
    top: 55px;
    border: none;

    @media screen and(max-width: $smallScreen) {
      position: initial;
      width: 100%;
      text-align: right;
      padding-right: 16px;
    }
  }
}

@media screen and (max-width: $largeScreen) {
  .option-radio {
    max-width: 80vw;
  }
}

.display-as-dependency.tertiary {
  .option-radio
    input[type='radio']:checked
    ~ .option-content-with-popup.option-radio-default {
    visibility: hidden;
  }
}

.display-as-dependency {
  .option-radio-default {
    visibility: hidden;
  }
  .option-radio
    input[type='radio']:checked
    ~ .option-content-with-popup.option-radio-default {
    visibility: visible;
  }
}
