.display-as-dependency {
  background: var(--white, #FFFFFF);
  border: 1px solid var(--light-gray, #E2E8F0);
  border-radius: 8px;
  color: var(--dark-blue, #0C354B);
  font-family: sofiaproblack, sans-serif;
  left: 0;
  min-width: 320px;
  padding: 40px 24px 10px;
  position: absolute;
  text-align: left;
  width: 370px;
  z-index: 4;
  box-shadow: 0 0 1px rgba(15, 23, 42, 0.06),
    0 10px 15px -3px rgba(15, 23, 42, 0.1),
    0 4px 6px -2px rgba(15, 23, 42, 0.05);

  &::before {
    content: '';
    position: absolute;
    bottom: 100%;
    border: 0.75rem solid transparent;
    border-top: none;
    border-bottom-color: var(--white, #FFFFFF);
    filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, 0.1));
  }

  .title-large {
    display: inline;
    padding-left: 0;
    margin-top: 24px;
    font-weight: 900;
    font-size: 20px;

    @media screen and (max-width: $smallScreen) {
      font-size: 16px;
    }
  }

  .input-boolean {
    margin-top: 20px;
    padding-bottom: 0;

    .content-wrapper
      .checkbox-option
      input[type='checkbox']:checked
      + label.option-title {
      min-height: 87px;

      .title {
        margin-top: 27px;

        @media screen and (max-width: $smallScreen) {
          margin-top: 20px;
        }
      }

      @media screen and (max-width: $smallScreen) {
        min-height: 65px;
      }
    }
  }

  .input-number {
    .content-wrapper {
      border: none;
      margin-top: 0;
      padding-bottom: 30px;
      padding-left: 0;
      position: relative;

      .question-title,
      .question-subtitle {
        display: block;

        @media screen and (max-width: $smallScreen) {
          font-size: 16px;
        }
      }

      .question-subtitle {
        @media screen and (max-width: $smallScreen) {
          font-family: sofiapromedium, sans-serif;
        }
      }
    }
  }

  input {
    width: 320px;
  }

  .input-text {
    .question-title {
      margin-top: 0;
      margin-bottom: 25px;
      font-size: 20px;
      text-align: left;

      @media screen and (max-width: $smallScreen) {
        font-size: 16px;
      }
    }

    .question-subtitle {
      line-height: 25px;
      font-size: 16px;
      margin-bottom: 10px;

      @media screen and (max-width: $smallScreen) {
        font-family: sofiapromedium, sans-serif;
      }
    }
  }

  .bottom-buttons,
  .btn-cancel,
  .btn-save {
    display: inline-block;
  }

  .bottom-buttons {
    text-align: right;
    width: 320px;
    margin-top: 32px;
    margin-bottom: 20px;

    button {
      letter-spacing: 0;
    }

    .btn-cancel {
      color: var(--dark-blue, #0C354B);
      opacity: 0.6;
      margin-right: 10px;
      border: none;
    }

    .btn-save,
    .btn-save-next {
      width: 95px;
      height: 40px;
      line-height: 25px;
      font-size: 16px;
    }

    .btn-save-next {
      &:after {
        display: inline-block;
        content: url('../../img/chevron-right.png');
        margin-left: 5px;
      }
    }
  }

  & > label + .input-boolean {
    margin-top: 24px;
  }

  .input-radio-wrapper {
    .option-radio {
      .content-wrapper .radio-option {
        span .title {
          margin: 0;
        }
      }
    }

    .question-title {
      font-size: 20px;
      margin-bottom: 0;
      margin-top: 0;
      text-align: left;
    }
  }

  .input-radio {
    .question-title {
      text-align: left;
      font-size: 24px;
      margin-top: 0;
      margin-bottom: 24px;
      color: var(--dark-blue, #0C354B);
    }
    .question-subtitle {
      margin-bottom: 8px;
      font-size: 14px;
      color: var(--dark-blue, #0C354B);

      @media screen and (max-width: $smallScreen) {
        font-family: sofiapromedium, sans-serif;
      }
    }
    .option-radio .content-wrapper {
      margin-top: 16px;
    }
    .option-radio:first-of-type .content-wrapper {
      margin-top: 0;
    }
  }

  .input-boolean {
    .option-radio {
      input[type='radio'] {
        line-height: 87px;

        &:not(:checked) ~ .content-wrapper .radio-option .radio {
          background: url('../../img/radio-unselected.svg') no-repeat center
            left;
          width: 28px;
          height: 28px;
        }

        &:checked ~ .content-wrapper .radio-option .radio {
          background: url('../../img/radio-selected.svg') no-repeat center left;
          width: 28px;
          height: 28px;
        }

        &:checked ~ .content-wrapper {
          border: 2px solid var(--primary-blue, #68A8FB);
        }
      }
    }
  }

  .option-title.input-radio {
    .title {
      margin-top: 0;
      margin-left: -22px;
      max-width: 220px;
      font-size: 15px;

      @media screen and(max-width: $smallScreen) {
        width: 280px;
      }
    }
  }
}
