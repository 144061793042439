.page-progress {
  color: var(--dark-blue, #0C354B);
  margin-top: -80px;

  &.bg-confetti {
    background: url('../../img/bg-confetti.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .progress-container {
    padding-top: 80px;
  }
  .milestone-title-wrapper {
    margin-left: 50px;

    h3 {
      font-family: sofiaproblack, sans-serif;
      max-width: $smallScreen;
      line-height: 46px;
      font-size: 36px;
      color: var(--dark-blue, #0C354B);
    }
    .sherpa-initial-progress-title,
    .results-title-description {
      font-family: sofiaprobold;
      font-style: normal;
      font-weight: 900;
      font-size: 24px;
      line-height: 36px;
      font-feature-settings: 'tnum' on, 'lnum' on;
      color: var(--dark-blue, #0C354B);
      max-width: 768px;
    }
  }

  .left-side {
    margin-top: 20px;
    z-index: 1;

    .line {
      padding-left: 18px;
      padding-top: 10px;

      &.dotted {
        height: 115px;
        width: 22px;
      }
    }

    .milestone-content-wrapper {
      margin-top: 8px;

      .milestone-subtitle,
      .btn-primary {
        font-family: sofiaprobold, serif;
      }

      .milestone-subtitle,
      .milestone-description,
      .btn {
        margin-left: 20px;
      }

      .milestone-subtitle {
        font-size: 20px;
      }

      .milestone-description {
        font-size: 16px;
        font-weight: 100;
        line-height: 24px;
        max-width: 508px;
        color: var(--dark-gray, #3D5D6F);
      }

      .btn-primary {
        margin-top: 15px;
        color: var(--dark-blue, #0C354B);
        letter-spacing: 0;
      }
    }

    .next,
    .update-results {
      margin-top: 50px;
      margin-bottom: 80px;
    }

    .next {
      margin-right: 50px;
      @media screen and (max-width: $smallScreen) {
        max-width: fit-content;
      }
    }
  }

  .right-side {
    text-align: right;
    position: absolute;
    right: 0;
    padding: 0;

    .graphic-plus {
      width: 659px;
      height: 606px;
    }
    .graphic-first-aid {
      width: 555px;
      height: 555px;
    }
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .right-side {
    display: none;
  }
}
