.edit-responses-popover {
  max-width: 1000px;
  max-height: 90vh;
  overflow-y: scroll;
}

.edit-responses {
  display: block;
  //position: relative;
  align-items: center;
  // margin-bottom: -80px;
  z-index: 6;
  //width: 400px;
  //float: right;

  .header-container {
    align-items: center;

    .title-large {
      display: inline;
      padding-left: 0;
      margin: 0;
      font-weight: 900;
      font-size: 24px;

      @media screen and (max-width: $smallScreen) {
        font-size: 16px;
      }
    }

    .close-button {
      margin-left: 10px;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .edit-menu {
    background: var(--white, #FFFFFF);
    //border: 1px solid var(--light-gray, #E2E8F0);
    //border-radius: 8px;
    color: var(--dark-blue, #0C354B);
    font-family: sofiaproblack, sans-serif;
    left: 0;
    max-width: 100%;
    min-width: 320px;
    padding: 0px 10px 0px 10px;
    text-align: left;
    width: 355px;
    z-index: 4;
    //box-shadow: 0 0 1px rgba(15, 23, 42, 0.06), 0 10px 15px -3px rgba(15, 23, 42, 0.1), 0 4px 6px -2px rgba(15, 23, 42, 0.05);

    .content-card {
      align-items: center;
      background: var(--white, #FFFFFF);
      border: 1px solid var(--light-gray, #E2E8F0);
      border-radius: 8px;
      display: flex;
      height: 80px;
      margin-top: 20px;
      padding: 0 0 0 22px;
      &:hover {
        cursor: pointer;
      }

      .option-icon {
        margin-right: 8px;
      }

      .card-title {
        margin: 0;
      }
    }
  }
}
