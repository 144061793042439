/*_fonts.scss*/

@font-face {
  font-family: 'sofiaproblack';
  src: url('../../fonts/SofiaProBlack/sofia_pro_black_webfont.woff2') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sofiaprobold';
  src: url('../../fonts/SofiaProBold/sofia_pro_bold_webfont.woff2') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sofiapromedium';
  src: url('../../fonts/SofiaProMedium/sofia_pro_medium_webfont.woff2') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'mhmarrowforward';
  src:  url('../../fonts/ArrowForward/arrow_forward.eot?8gcext');
  src:  url('../../fonts/ArrowForward/arrow_forward.eot?8gcext#iefix') format('embedded-opentype'),
  url('../../fonts/ArrowForward/arrow_forward.ttf?8gcext') format('truetype'),
  url('../../fonts/ArrowForward/arrow_forward.woff?8gcext') format('woff'),
  url('../../fonts/ArrowForward/arrow_forward.svg?8gcext#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'mhmarrowback';
  src:  url('../../fonts/ArrowBack/arrow_back.eot?8uahlr');
  src:  url('../../fonts/ArrowBack/arrow_back.eot?8uahlr#iefix') format('embedded-opentype'),
  url('../../fonts/ArrowBack/arrow_back.ttf?8uahlr') format('truetype'),
  url('../../fonts/ArrowBack/arrow_back.woff?8uahlr') format('woff'),
  url('../../fonts/ArrowBack/arrow_back.svg?8uahlr#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'mhmchevrondown';
  src:  url('../../fonts/ChevronDown/chevron_down.eot?pdhzyh');
  src:  url('../../fonts/ChevronDown/chevron_down.eot?pdhzyh#iefix') format('embedded-opentype'),
  url('../../fonts/ChevronDown/chevron_down.ttf?pdhzyh') format('truetype'),
  url('../../fonts/ChevronDown/chevron_down.woff?pdhzyh') format('woff'),
  url('../../fonts/ChevronDown/chevron_down.svg?pdhzyh#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
