/*_colors.scss*/
$black: #000;

:root{
  --white: #FFFFFF;
  --light-black: #0F172A;
  --pale-white: #FAFAFA;
}

/*
  Primary Colors
*/
:root {
  --primary-red: #FD7B56;
  --primary-blue: #68A8FB;
}

/*
  Secondary Colors
*/
:root {
  --dark-blue: #0C354B;
  --yellow: #FECB60;
  --light-blue: #CAE1FF;
  --light-red: #FFAB93;
  --gray: #FFAB93;
  --light-gray: #E2E8F0;
  --off-white: #F6FAFF;
  --dark-green:  #10B981;
  --hyke-dark-blue: #3D5D6F;
  --hyke-success-green: #6EE7B7;
  --hyke-worst-case-red: #F14261;
}

/*
  Tertiary Colors
  For use only on alerts, indicators, graphs, and system responses.
*/
:root {
  --light-green: #BAF7D0;
  --pink: #FDA7A7;
  --orange: #F39D2A;
  --light-orange: #FFAB93;
  --medium-blue: #1D60C1;
  --mid-gray: #9EAEB7;
  --dark-gray: #3D5D6F;
  --error-red: #F43F5E;
  --success-green: #20D4A9;
}

