#page-providers{
  .form-content{
    display: inline-block;
    max-width: 960px;
    font-size: 36px;
    font-weight: 900;
    font-style: normal;
    margin-bottom: 76px;
    line-height: 48.6px;
  }
}
