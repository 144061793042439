.page-cbr {
  section {
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .card {
    box-shadow: 0px 0px 1px rgba(15, 23, 42, 0.06), 0px 4px 6px rgba(15, 23, 42, 0.1), 0px 2px 4px rgba(15, 23, 42, 0.06);
    border: 1px solid var(--light-gray, #E2E8F0);
    box-sizing: border-box;
    border-radius: 8px;
  }

  .speech-bubble {
    text-align: center;
  }
}
