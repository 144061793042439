.question-text {
  line-height: 48.6px;

  h2{
    text-align: center;
    margin: 0 auto;
    max-width: 960px;
  }
}

.text-orange {
  color: var(--primary-red, #FD7B56);
}

.headline {
  margin-bottom: 62px;
}
