:root {
  --btn-primary-hover-color-sherpa: #FA673E;
}

.sherpa-landing-page-container {
  background-color: var(--off-white, #F6FAFF);
  overflow-x: hidden;
  position: relative;

  @media screen and (max-width: $smallScreen) {
    padding-left: 15px;
    padding-right: 15px;
  }

  .landing-page-content {
    position: relative;
    z-index: 2;
  }

  .landing-page-background {
    position: absolute;
    z-index: 1;
    left: 76%;
    bottom: 35%;

    @media screen and (max-width: $smallScreen) {
      display: none;
    }

    .background-img {
      width: 589px;
      height: 514px;
    }
  }

  .header-row {
    font-family: sofiaprobold;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 136.5%;
    text-align: center;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--dark-gray, #3D5D6F);
    margin-bottom: 35px;

    @media screen and (max-width: $smallScreen) {
      margin-top: 0px;
    }

    .header-col {
      max-width: 591px;
      margin-right: auto;
      margin-left: auto;
    }

    .first-header-line {
      margin-bottom: 35px;
    }

    .second-header-line {
      margin-bottom: 35px;
    }

    .how-it-works-text {
      font-family: sofiaprobold;
      font-style: normal;
      font-weight: 900;
      font-size: 27px;
      line-height: 136.5%;
      text-align: center;
      font-feature-settings: 'tnum' on, 'lnum' on;
      color: var(--primary-blue, #68A8FB);
    }
  }

  .steps-row {
    margin-bottom: 35px;
    padding-left: 135px;
    padding-right: 171px;

    @media screen and (max-width: $smallScreen) {
      display: revert !important;
      padding-left: 0px;
      padding-right: 0px;
    }

    .step-col {
      background: var(--white, #FFFFFF);
      border-radius: 8px;
      width: 264px;
      max-width: 264px;
      min-height: 301px;
      margin-right: -12px;
      margin-left: -12px;

      @media screen and (max-width: $smallScreen) {
        margin-left: auto;
        margin-right: auto;
      }

      .step-img-1 {
        width: 103px;
        height: 94px;
        margin-top: 61px;
        margin-bottom: 38px;
      }
      .step-img-2 {
        width: 93px;
        height: 93px;
        margin-top: 62px;
        margin-bottom: 38px;
      }
      .step-img-3 {
        width: 97px;
        height: 93px;
        margin-top: 62px;
        margin-bottom: 38px;
      }
      .step-img-4 {
        width: 104px;
        height: 94px;
        margin-top: 61px;
        margin-bottom: 38px;
      }
      .step-title-text {
        font-family: sofiaprobold;
        font-style: normal;
        font-weight: 900;
        font-size: 18px;
        line-height: 136.5%;
        text-align: center;
        font-feature-settings: 'tnum' on, 'lnum' on;
        color: var(--dark-blue, #0C354B);
        margin-bottom: 14px;
      }
      .step-subtitle-text {
        font-family: sofiapromedium;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        font-feature-settings: 'tnum' on, 'lnum' on;
        color: var(--dark-gray, #3D5D6F);
        margin-bottom: 24px;
      }
    }

    .arrow-col {
      max-width: min-content;
      margin-top: auto;
      margin-bottom: auto;
      padding: 0px;
      z-index: 3;

      @media screen and (max-width: $smallScreen) {
        margin-left: auto;
        margin-right: auto;
      }

      .arrow-img {
        width: 48px;
        height: 48px;
      }
    }
  }

  .footer-row {
    .get-protected-big-text {
      font-family: sofiaprobold;
      font-style: normal;
      font-weight: 900;
      font-size: 18px;
      line-height: 136.5%;
      text-align: center;
      font-feature-settings: 'tnum' on, 'lnum' on;
      color: var(--dark-blue, #0C354B);
      margin-bottom: 10px;
    }
    .get-protected-small-text {
      font-family: sofiapromedium;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      font-feature-settings: 'tnum' on, 'lnum' on;
      color: var(--dark-gray, #3D5D6F);
      margin-bottom: 35px;
    }
    .ready-to-start-text {
      font-family: sofiaprobold;
      font-style: normal;
      font-weight: 900;
      font-size: 27px;
      line-height: 136.5%;
      text-align: center;
      font-feature-settings: 'tnum' on, 'lnum' on;
      color: var(--primary-blue, #68A8FB);
      margin-bottom: 15px;
    }
    .terms-conditions-text {
      font-family: sofiaprobold;
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      line-height: 16px;
      font-feature-settings: 'tnum' on, 'lnum' on;
      color: #000000;
      margin-top: 15px;

      span {
        color: var(--primary-red, #FD7B56);
      }
    }
    .continue-button {
      font-family: sofiaprobold;
      font-style: normal;
      font-weight: 900;
      font-size: 20px;
      line-height: 26px;
      align-items: center;
      text-align: center;
      font-feature-settings: 'tnum' on, 'lnum' on;
      color: var(--white, #FFFFFF);

      justify-content: center;
      align-items: center;
      padding: 12px 24px;
      width: 360px;
      height: 64px;
      background: var(--primary-red, #FD7B56);
      border-radius: 90px;

      @media screen and (max-width: $smallScreen) {
        width: fit-content;
      }
    }

    .continue-button:hover {
      background: var(--btn-primary-hover-color-sherpa, #FA673E);
    }
  }
}
