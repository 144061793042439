/*_fontStyles.scss*/
html body {
  font-family: sofiapromedium, sans-serif;

  h2, h3, h6, p {
    color: var(--dark-blue, #0C354B);
  }

  /*Titles font*/
  h2, h3, h6 {
    font-family: sofiaproblack, sans-serif;
    font-weight: 900;
  }

  h2 {
    font-size: 36px;
  }

  h3 {
    font-size: 24px;
  }

  h6 {
    font-size: 18px;
  }

  p {
    font-size: 16px;
  }

  .bold-text {
    font-family: sofiaprobold, sans-serif;
  }

  .small-text {
    font-size: 11px;
  }

}
