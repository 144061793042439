.contact-us-cta-card {
  .card {
    padding: 27px 27px 38px 29px;
    height: 100%;
  }

  .cta-card-description {
    margin-bottom: 35px;
    font-size: 14px;
    color: var(--dark-gray, #3D5D6F);
  }

  .cta-card-button {
    font-size: 16px;
  }

  .img-wrapper {
    width: 100%;
    height: 144px;

    img {
      height: 100%;
      width: 100%;
    }
  }
}
