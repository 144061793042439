/*_layout.scss*/
body { margin: 0; }

a {
  color: var(--primary-red, #FD7B56);
  text-decoration: none;

  &:hover {
    color: var(--light-red, #FFAB93);
  }
}

.main-dialogue {
  padding-bottom: 20px;
}

.layout-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.layout-wrapper {
  position: relative;
  margin-right: auto;
  margin-left: auto;
  padding-top: 154px;
  width: 100%;
  background: var(--off-white, #F6FAFF);
  flex: 2;
  overflow-x: hidden;
}

/* layout breakpoints */
$smallScreen: 768px;
$mediumScreen: 992px;
$largeScreen: 1024px;
