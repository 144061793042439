.learn-more-hsa-fsa-container {
  padding-left: 15px;
  padding-right: 15px;
  max-width: 100%;
  overflow-x: hidden;

  .container {
    max-width: 998px;
  }

  .page-title {
    font-family: sofiaprobold;
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 135%;
    text-align: center;
    color: var(--light-black, #0F172A);
    padding-left: 57px;
    padding-right: 57px;
  }

  .bottom-theo-row {
    margin-top: 32px;

    .bottom-theo {
      padding: 0px;
    }

    .card-title-wrapper {
      margin-left: 19px;
      margin-right: 17px;
      padding: 0px;
    }

    .expandable-card {
      box-sizing: border-box;
      background: var(--white, #FFFFFF);
      box-shadow: 0px 0px 1px rgba(15, 23, 42, 0.06),
        0px 4px 6px rgba(15, 23, 42, 0.1), 0px 2px 4px rgba(15, 23, 42, 0.06);
      border-radius: 8px;
    }
  }

  .education-component-container {
    box-shadow: 0px 0px 1px rgba(15, 23, 42, 0.06),
      0px 4px 6px -1px rgba(15, 23, 42, 0.1),
      0px 2px 4px -1px rgba(15, 23, 42, 0.06);
    border-radius: 8px;
    margin-top: 64px;
    padding-left: 42px;
    padding-right: 42px;
    padding-top: 40px;
    background-color: var(--white, #FFFFFF);

    .title-row {
      margin-bottom: 48px;
      padding: 0px;
    }

    .hsa-education-title-icon,
    .fsa-education-title-icon {
      width: 150px;
      height: 150px;
      margin-right: 32px;
      padding: 0px;
    }

    .title {
      font-family: sofiaprobold;
      font-style: normal;
      font-weight: 900;
      font-size: 36px;
      line-height: 135%;
      font-feature-settings: 'tnum' on, 'lnum' on;
      color: var(--dark-blue, #0C354B);
    }

    .small-text {
      font-family: sofiaprobold;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      text-transform: uppercase;
      font-feature-settings: 'tnum' on, 'lnum' on;
      color: var(--mid-gray, #9EAEB7);
    }

    .max-contribution {
      font-family: sofiaprobold;
      font-style: normal;
      font-weight: 900;
      font-size: 24px;
      line-height: 36px;
      font-feature-settings: 'tnum' on, 'lnum' on;
      color: var(--dark-blue, #0C354B);
      margin-top: 16px;
    }

    .education-toggle-button {
      font-family: sofiaprobold;
      font-style: normal;
      font-weight: 900;
      font-size: 16px;
      line-height: 24px;
      font-feature-settings: 'tnum' on, 'lnum' on;
      color: var(--dark-blue, #0C354B);
      letter-spacing: normal;
      border-bottom: 2px dashed var(--primary-red, #FD7B56);
    }

    .education-toggle-button:hover {
      cursor: pointer;
    }

    .chevron-icon::after, .chevron-icon-down::after {
      margin-left: 10px;
      margin-right: 20px;
      font-family: 'mhmchevrondown', sans-serif;
      color: var(--primary-red, #FD7B56);
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      font-size: 24px;
      text-transform: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      display: inline-block;
      content: '\e900';
      vertical-align: middle;
      border: none;
    }

    .chevron-icon-down::after {
      transform: rotate(180deg);
    }

    .theo-row {
      .theo-row-container {
        position: relative;
        min-width: 100%;
      }

      .speech-bubble-hsafsa {
        border-radius: 25px;
        padding: 32px;
        border: 2px solid var(--light-gray, #E2E8F0);
        margin-bottom: 25px;

        .section-title {
          font-family: sofiaprobold;
          font-style: normal;
          font-weight: 900;
          font-size: 20px;
          line-height: 26px;
          font-feature-settings: 'tnum' on, 'lnum' on;
          color: var(--dark-blue, #0C354B);
          padding-bottom: 11px;
        }

        .section-content {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          font-feature-settings: 'tnum' on, 'lnum' on;
          color: var(--dark-gray, #3D5D6F);
          padding: 0px;
          margin: 0px;
        }
      }

      .theo-img-col {
        margin-top: 8px;
        position: relative;
        .theo-left-icon {
          width: 73.8px;
          padding: 0px;
          margin-right: 30px;
        }

        &:before {
          content: "";
          position: absolute;
          bottom: 63px;
          right: 114px;
          transform: skew(45deg);
          z-index: 4;
          width: 0;
          height: 0;
          border: 1.1rem solid transparent;
          border-bottom: none;
          border-top: 1.1rem solid var(--white, #FFFFFF);
          filter: drop-shadow(0 1mm 0.7mm rgba(15, 23, 42, 0.06));
          display: block;
        }
        &:after {
          content: "";
          position: absolute;
          bottom: 59px;
          right: 112px;
          transform: skew(45deg);
          z-index: 3;
          width: 0;
          height: 0;
          border: 1.3rem solid transparent;
          border-bottom: none;
          border-top: 1.3rem solid rgba(27, 31, 35, 0.15);
          filter: drop-shadow(0 1mm 0.7mm rgba(15, 23, 42, 0.06));
          display: block;
        }
      }
    }

    .how-it-works-row {
      padding-left: 30px;
      margin-top: 32px;

      .how-it-works-col {
        margin-right: 25px;
      }

      .how-it-works-title {
        font-family: sofiaprobold;
        font-style: normal;
        font-weight: 900;
        font-size: 20px;
        line-height: 26px;
        font-feature-settings: 'tnum' on, 'lnum' on;
        color: var(--dark-blue, #0C354B);
        margin-bottom: 17px;
      }

      .image-number {
        font-family: sofiaprobold;
        font-style: normal;
        font-weight: 900;
        font-size: 16px;
        line-height: 24px;
        font-feature-settings: 'tnum' on, 'lnum' on;
        color: var(--dark-blue, #0C354B);
      }

      .how-it-works-image {
        height: 117px;
        margin-bottom: 14px;
      }

      .number-image-grouping {
        margin-left: 10px;
      }

      .image-text {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        font-feature-settings: 'tnum' on, 'lnum' on;
        color: var(--dark-gray, #3D5D6F);
      }

      .image-text.image-1-text-fsa {
        padding-right: 50px;
      }
      .image-text.image-2-text-hsa {
        padding-right: 75px;
      }
      .image-text.image-2-text-fsa {
        padding-right: 50px;
      }
    }

    .perks-row {
      box-sizing: border-box;
      background: var(--white, #FFFFFF);
      border: 1px solid var(--light-blue, #CAE1FF);
      border-radius: 8px;
      margin-top: 40px;
      margin-bottom: 41px;
      margin-left: 0px;
      margin-right: 0px;

      .perks-title {
        font-family: sofiaprobold;
        font-style: normal;
        font-weight: 900;
        font-size: 20px;
        line-height: 26px;
        font-feature-settings: 'tnum' on, 'lnum' on;
        color: var(--dark-blue, #0C354B);
        margin-top: 24px;
        margin-bottom: 24px;
        margin-left: 32px;
        padding: 0px;
      }
    }

    .other-things-row {
      box-sizing: border-box;
      background: var(--white, #FFFFFF);
      border: 1px solid var(--light-blue, #CAE1FF);
      border-radius: 8px;
      margin-bottom: 48px;

      .other-things-title {
        font-family: sofiaprobold;
        font-style: normal;
        font-weight: 900;
        font-size: 18px;
        line-height: 136.5%;
        font-feature-settings: 'tnum' on, 'lnum' on;
        color: var(--dark-blue, #0C354B);
        margin-top: 24px;
        margin-bottom: 24px;
        margin-left: 32px;
      }
    }

    table {
      width: 100%;
    }

    tr {
      width: 100%;
      min-height: 56px;
      display: flex;
    }

    tr:nth-child(odd) {
      background: var(--off-white, #F6FAFF);
    }

    tr:last-child {
      border-radius: 8px;
    }

    td {
      margin-top: auto;
      margin-bottom: auto;
    }

    .icon-td {
      padding-left: 32px;
      margin-right: 8px;
    }

    .text-td {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      font-feature-settings: 'tnum' on, 'lnum' on;
      color: var(--dark-gray, #3D5D6F);
    }
  }

  .education-component-container.first-section {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .education-component-container.second-section {
    margin-top: 2px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .education-component-container.second-section-hide {
    display: none;
  }

  .contribution-selector {
    .contribution-selector-intro {
      font-family: sofiaprobold;
      font-style: normal;
      font-weight: 900;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      font-feature-settings: 'tnum' on, 'lnum' on;
      color: var(--dark-blue, #0C354B);
      margin-top: 80px;
      margin-bottom: 32px;
    }

    .contribution-selector-container {
      box-sizing: border-box;
      background: var(--white, #FFFFFF);
      border: 1px solid var(--light-gray, #E2E8F0);
      box-shadow: 0px 0px 1px rgba(15, 23, 42, 0.06),
        0px 4px 6px rgba(15, 23, 42, 0.1), 0px 2px 4px rgba(15, 23, 42, 0.06);
      border-radius: 8px;
      padding: 0px;

      .title-row {
        margin-top: 32px;
        margin-bottom: 32px;
        padding-left: 11px;
        padding-right: 11px;

        .title-icon {
          width: 96px;
          height: 96px;
          padding: 0px;
          margin-left: 32px;
        }

        .title-subtitle-group {
          padding: 0px;
          margin-left: 21px;
        }

        .title {
          font-family: sofiaprobold;
          font-style: normal;
          font-weight: 900;
          font-size: 24px;
          line-height: 36px;
          font-feature-settings: 'tnum' on, 'lnum' on;
          color: var(--dark-blue, #0C354B);
        }

        .subtitle {
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 26px;
          display: flex;
          align-items: center;
          font-feature-settings: 'tnum' on, 'lnum' on;
          color: var(--dark-gray, #3D5D6F);
        }
      }

      hr {
        width: 100%;
        margin-top: 0px;
        margin-bottom: 38px;
      }

      .contribution-slider-section {
        padding-left: 31px;
        padding-right: 31px;
        padding-bottom: 40px;

        .grouped-rows {
          padding-left: 14px;
          padding-right: 14px;
        }

        .contribution-text-row {
          margin-bottom: 32px;
          padding-left: 4px;
          padding-right: 4px;

          .big-text {
            font-family: sofiaprobold;
            font-style: normal;
            font-weight: 900;
            font-size: 20px;
            line-height: 26px;
            font-feature-settings: 'tnum' on, 'lnum' on;
            color: var(--dark-blue, #0C354B);
            margin-bottom: 8px;
            padding-left: 0px;
          }

          .small-text {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            font-feature-settings: 'tnum' on, 'lnum' on;
            color: var(--dark-gray, #3D5D6F);
            padding-left: 0px;
          }
        }

        .contribution-slider-row {
          padding-left: 4px;
          padding-right: 4px;

          .contribution-slider-row-container {
            padding: 0px;

            .slider-bottom-row {
              margin-top: 16px;

              .bottom-value {
                font-family: sofiaprobold;
                font-style: normal;
                font-weight: 900;
                font-size: 16px;
                line-height: 24px;
                align-items: center;
                font-feature-settings: 'tnum' on, 'lnum' on;
                color: var(--dark-blue, #0C354B);
                margin-bottom: 2px;
              }

              .bottom-text {
                font-style: normal;
                font-weight: 500;
                font-size: 11px;
                line-height: 16px;
                font-feature-settings: 'tnum' on, 'lnum' on;
                color: var(--dark-blue, #0C354B);
              }
            }
          }

          .slider {
            .slider-value {
              .slider-value-col {
                .slider-value-box-container {
                  padding-right: 120px;
                }

                .slider-value-triangle-container {
                  padding-left: 7px;
                  padding-right: 25px;
                  height: 22px;

                  .slider-triangle-icon {
                    position: relative;
                    top: -11px;
                  }

                  .slider-triangle-icon::after {
                    color: var(--primary-red, #FD7B56);
                    font-style: normal;
                    font-weight: normal;
                    font-variant: normal;
                    font-size: 22px;
                    text-transform: none;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    content: '\25bc';
                  }
                }
              }

              .slider-value-box {
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 24px;
                width: 120px;
                height: 56px;
                background: var(--white, #FFFFFF);
                border: 1px solid var(--light-gray, #E2E8F0);
                border-radius: 8px;
                position: relative;
                cursor: text;

                .slider-value-number {
                  font-family: sofiaprobold;
                  font-style: normal;
                  font-weight: 900;
                  font-size: 20px;
                  line-height: 26px;
                  display: flex;
                  align-items: center;
                  font-feature-settings: 'tnum' on, 'lnum' on;
                  color: var(--light-black, #0F172A);

                  .input-value {
                    border: none;
                    background: transparent;
                    width: 75px;
                    padding: 0px;
                  }

                  .non-input-value-hide,
                  .input-value-hide {
                    display: none;
                  }

                  .dollar-sign {
                    color: var(--mid-gray, #9EAEB7);
                    margin-right: 4px;
                  }
                }
              }

              img {
                margin-bottom: 14px;
                position: relative;
              }
            }

            .field {
              input {
                padding: 0px;
                -webkit-appearance: none;
                -moz-appearance: none;
                height: 16px;
                border-radius: 57px;
                border: none;
              }

              input::-webkit-slider-thumb {
                -webkit-appearance: none;
                -moz-appearance: none;
                width: 32px;
                height: 32px;
                background: var(--white, #FFFFFF);
                border: 8px solid var(--primary-red, #FD7B56);
                border-radius: 50%;
                box-shadow: 0px 0px 1px rgba(15, 23, 42, 0.06),
                  0px 4px 6px -1px rgba(15, 23, 42, 0.1),
                  0px 2px 4px -1px rgba(15, 23, 42, 0.06);
                cursor: pointer;
              }

              input::-moz-range-thumb {
                -webkit-appearance: none;
                -moz-appearance: none;
                width: 32px;
                height: 32px;
                background: var(--white, #FFFFFF);
                border: 8px solid var(--primary-red, #FD7B56);
                border-radius: 50%;
                box-shadow: 0px 0px 1px rgba(15, 23, 42, 0.06),
                  0px 4px 6px -1px rgba(15, 23, 42, 0.1),
                  0px 2px 4px -1px rgba(15, 23, 42, 0.06);
                cursor: pointer;
              }
            }
          }
        }

        .breakdown-cards-row {
          margin-bottom: 24px;
          margin-top: 24px;

          .breakdown-card {
            box-sizing: border-box;
            background: var(--white, #FFFFFF);
            border: 1px solid var(--light-gray, #E2E8F0);
            border-radius: 8px;
            padding-left: 24px;

            .breakdown-card-title {
              font-family: sofiaprobold;
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              line-height: 16px;
              text-transform: uppercase;
              font-feature-settings: 'tnum' on, 'lnum' on;
              color: var(--dark-gray, #3D5D6F);
              margin-top: 23px;
              margin-bottom: 4px;
            }
            .breakdown-card-subtitle {
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 18px;
              font-feature-settings: 'tnum' on, 'lnum' on;
              color: var(--dark-gray, #3D5D6F);
              margin-bottom: 8px;
            }
            .breakdown-card-value {
              font-family: sofiaprobold;
              font-style: normal;
              font-weight: 900;
              font-size: 36px;
              line-height: 135%;
              font-feature-settings: 'tnum' on, 'lnum' on;
              color: var(--light-black, #0F172A);
              margin-bottom: 16px;
            }
          }

          .breakdown-card.first-3 {
            margin-right: 25px;
          }
        }

        .tax-savings-row {
          box-sizing: border-box;
          background: rgba(255, 255, 255, 0.2);
          border: 1px solid var(--light-gray, #E2E8F0);
          border-radius: 8px;
          padding-right: 24px;
          padding-left: 24px;
          min-height: 131px;

          .left-col-outer {
            padding: 0px;
            max-width: 230px;
            margin-right: 116px;

            .left-col-inner {
              box-sizing: border-box;
              background: var(--white, #FFFFFF);
              border: 1px solid var(--light-gray, #E2E8F0);
              border-radius: 4px;
              width: 230px;
              height: 91px;
              padding-left: 18px;
              padding-top: 5px;

              .tax-savings-value {
                font-family: sofiaprobold;
                font-style: normal;
                font-weight: 900;
                font-size: 36px;
                line-height: 135%;
                font-feature-settings: 'tnum' on, 'lnum' on;
                color: var(--dark-blue, #0C354B);
              }

              .tax-savings-text {
                font-family: sofiaprobold;
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 16px;
                text-transform: uppercase;
                font-feature-settings: 'tnum' on, 'lnum' on;
                color: var(--dark-gray, #3D5D6F);
              }
            }
          }

          .right-col-outer {
            padding: 0px;

            .right-col-inner {
              padding: 0px;
              margin-right: 90px;

              .tax-savings-explanation {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                font-feature-settings: 'tnum' on, 'lnum' on;
                color: var(--dark-blue, #0C354B);
              }
            }
          }
        }

        .tax-savings-row.green-background {
          background: linear-gradient(
            90.01deg,
            var(--dark-green, #10B981) 0.01%,
            rgba(255, 255, 255, 0) 50%
          );
        }
      }
    }
  }
}
