.input-response {
  padding-bottom: 10px;
  text-align: left;

  hr {
    margin-top: 0;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
    color: var(--light-gray, #E2E8F0);
    border: 2px solid;
  }

  .response-content {
    min-height: 38px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 5px;

    .response-answer {
      display: inline-block;
      max-width: 70%;
      text-transform: capitalize;
      color: var(--dark-blue, #0C354B);
    }

    .btn-remove {
      padding: 0;
      letter-spacing: 0;
      color: var(--dark-blue, #0C354B);
      border: none;

      @media screen and(max-width: $smallScreen) {
        float: none !important;
        width: 100%;
        text-align: right;
      }

      img {
        padding-right: 5px;
      }
    }
  }
}

.btn-edit {
  color: var(--dark-blue, #0C354B);
  height: 40px;
  letter-spacing: 0;
  margin-right: 15px;
  padding: 0;
  position: absolute;
  right: 0;
  top: 25px;
  text-align: left;
  border: none;

  @media screen and(max-width: $smallScreen) {
    position: initial;
    width: 100%;
    text-align: right;
    padding-right: 16px;
  }

  img {
    padding-right: 5px;
  }
}
