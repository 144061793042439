.popup-content {
  width: 500px;
}

.magic-link-button {
  width: 250px;
}

.popup-content .modal .header {
  padding-left: 0px;
}

.popup-content .modal .content {
  padding-right: 0px;
}

@media (max-width:525px) {
  .popup-content {
    width: 95%;
  }

  .popup-content .modal .header {
    font-size: 21px;
  }

  .popup-content .modal .content {
    font-size: 15px;
  }
}

@media (max-width:250px) {
  .magic-link-button {
    width: 100%;
  }
}

.magic-link-email {
  margin-top: 10px;
  font-size: 20px;
  height: 69px;

  background: var(--white, #FFFFFF);
  border: 2px solid var(--dark-gray, #3D5D6F);
  border-radius: 8px;
  padding: 24px 0 24px 24px;
}

.magic-link-email:focus {
  border: 2px solid var(--dark-gray, #3D5D6F);
}

.magic-link-message {
  color: var(--dark-green, #10B981);
  font-size: 20px;
  margin-top: 5px;
}

.magic-link-error {
  color: var(--error-red, #F43F5E);
}

.magic-link-saved {
  color: var(--dark-green, #10B981);
  font-weight: bold;
  margin-right: 5px;
}

.magic-link-button:disabled {
  background-color: var(--light-orange,#FFAB93);
  cursor: default;
}