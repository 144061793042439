.input-text {
  max-width: 320px;
  display: block;
  margin: 0 auto 20px;

  input {
    background: var(--white, #FFFFFF);
    border: 1px solid var(--light-gray, #E2E8F0);
    border-radius: 8px;
    box-sizing: border-box;
    display: block;
    padding: 24px 0 24px 24px;
    width: 320px;

    &:focus-visible {
      border: 1px solid var(--dark-blue, #0C354B);
    }
  }

  .question-title {
    display: block;
    font-size: 16px;
    margin-bottom: 8px;
    margin-top: 40px;
    text-align: left;
  }

  .array-number-index {
    display: block;

    h2, input {
      display: inline-block;
    }

    h2 {
      width: 15%;
      padding-top: 10px;
    }

    input {
      width: 85%;
      float: right;
    }

    .subtitle {
      display: block;
      margin-top: 24px;
      margin-bottom: 8px;
      padding-left: 15%;
    }
  }
}

