.input-boolean {
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  min-height: 87px;
  max-width: 34vw;
  padding-bottom: 10px;
  position: relative;
  width: 100%;
  max-height: fit-content;

  @media screen and (max-width: $smallScreen) {
    min-height: 65px;
  }

  .content-wrapper {
    text-align: left;

    .checkbox-option input[type='checkbox'] {
      display: none;
    }

    .checkbox-option .title {
      max-width: 75%;
      max-height: fit-content;
      overflow: hidden;
      margin-left: 41px;
      margin-top: 28px;
      margin-bottom: 14px;

      .checkbox-option input[type='checkbox']:checked + .title {
        margin-top: 28px;
        margin-bottom: 14px;
      }

      @media screen and (max-width: $smallScreen) {
        margin-top: 14px;
        margin-bottom: 14px;
      }
    }

    .checkbox-option input[type='checkbox'] + label {
      background: url('../../img/checkbox-unchecked.png') top 50% left 0
          no-repeat,
        var(--white, #FFFFFF);
      background-origin: content-box;
      border: 1px solid var(--light-gray, #E2E8F0);
      border-radius: 8px;

      @media screen and (max-width: $smallScreen) {
        background: url('../../img/checkbox-unchecked.png') top 50% left 0
            no-repeat,
          var(--white, #FFFFFF);
        background-origin: content-box;
      }
    }

    .checkbox-option input[type='checkbox']:checked + label {
      background: url('../../img/checkbox-checked.png') top 50% left 0 no-repeat,
        var(--white, #FFFFFF);
      background-origin: content-box;
      border: 2px solid var(--primary-blue, #68A8FB);

      @media screen and (max-width: $smallScreen) {
        background: url('../../img/checkbox-checked.png') top 50% left 0
            no-repeat,
          var(--white, #FFFFFF);
        background-origin: content-box;
      }

      &.option-title {
        padding-left: 23px;

        &.follow-up {
          @media screen and (max-width: $smallScreen) {
            .title {
              margin-top: 18px;
            }
          }
        }
      }
    }

    .checkbox-option input[type='checkbox']:checked + label.follow-up {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom: none;
    }

    .checkbox-option input[type='checkbox']:disabled + label {
      background: url('../../img/checkbox-unchecked.png') top 30px left 0
          no-repeat,
        var(--light-gray, #E2E8F0);
      background-origin: content-box;
      color: var(--mid-gray, #9EAEB7);
      pointer-events: none;

      @media screen and(max-width: $smallScreen) {
        background: url('../../img/checkbox-unchecked.png') top 18px left 23px
            no-repeat,
          var(--light-gray, #E2E8F0);
      }
    }

    .checkbox-option .display-as-dependency {
      top: 80px;

      .input-wrapper {
        margin-bottom: 16px;
      }
    }

    .input-response {
      padding-bottom: 10px;

      .response-content {
        min-height: 38px;
        padding-bottom: 5px;

        .response-answer {
          display: inline-block;
          padding: 1px 0 0 20px;
          text-transform: capitalize;
        }

        .btn-remove {
          border: none;

          @media screen and(max-width: $smallScreen) {
            float: none !important;
            width: 100%;
            text-align: right;
          }

          img {
            padding-right: 5px;
          }
        }
      }
    }

    .btn-edit {
      color: var(--dark-blue, #0C354B);
      height: 20px;
      letter-spacing: 0;
      margin-right: 5px;
      padding: 0;
      position: absolute;
      margin-bottom: 8px;
      right: 0;
      top: 25px;
      text-align: left;
      width: 65px;
      border: none;

      @media screen and(max-width: $smallScreen) {
        margin-top: -12px;
        position: initial;
        width: 100%;
        text-align: right;
        padding-right: 16px;
      }

      img {
        padding-right: 5px;
      }
    }

    .option-title {
      min-height: 87px;
      padding-left: 24px;
      padding-right: 22px;
      width: 100%;
      cursor: pointer;

      @media screen and (max-width: $smallScreen) {
        min-height: 65px;
      }

      .question-content {
        display: block;

        span,
        button {
          display: inline-block;
        }

        .follow-up-subtitle {
          margin-top: 4px;
          margin-left: 42px;
        }
      }
    }
  }
}

@media screen and (max-width: $largeScreen) {
  .input-boolean {
    max-width: 80vw;
  }
}

.small-title-text {
  font-size: 18px;
}

.normal-title-text {
  font-size: 20px;
}
