.option-checkbox-card {
  label {
    display: block;
    box-sizing: border-box;
    outline: none;
  }
  input[type='checkbox'] {
    display: none;

    &:checked ~ label .white-card .check-mark {
      background-color: var(--primary-blue, #68A8FB);
      border: none;
    }
    &:checked ~ label .white-card .check-mark:after {
      display: block;
    }

    &:checked ~ label .white-card {
      border: 2px solid var(--primary-blue, #68A8FB);
    }

    &:disabled ~ label {
      opacity: 0.5;

      .white-card {
        cursor: not-allowed;
      }
    }
  }
  .check-mark {
    width: 21px;
    height: 21px;
    background-color: var(--white, #FFFFFF);
    border: 1px solid var(--mid-gray, #9EAEB7);
    border-radius: 1px;
    position: absolute;
  }

  .check-mark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .check-mark:after {
    left: 6px;
    top: 2px;
    width: 7px;
    height: 12px;
    border: solid var(--white, #FFFFFF);
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .white-card {
    height: 272px;
    padding: 22px;
    position: relative;
    cursor: pointer;
  }
  .checkbox-card-image{
    width: 90px;
    height: 90px;
    outline: none;
  }
  .card-title{
    font-size: 18px;
    line-height: 24.57px;
  }
}
