:root {
  --theo-image: url("../../img/theo.svg");
  --whitelabel-mask-image: none;
  --whitelabel-mask-color: rgba(0, 0, 0, 0);
}

/* SASS vars */
$desktop: 1025px;

/* popup animation */

@keyframes anvil {
  0% {
    transform: scale(1) translateY(0);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}

.theo {
  background-image: var(--theo-image, url("../../img/theo.svg"));
  background-color: var(--whitelabel-mask-color, rgba(0, 0, 0, 0));
  mask-image: var(--whitelabel-mask-image, none);
  -webkit-mask-image: var(--whitelabel-mask-image, none);
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  width: 88px;
  height: 53px;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  position: relative;
  transition: 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;

  @media screen and (min-width: $desktop) {
    width: 115px;
    height: 70px;
  }

  &:before,
  &:after {
    display: block;
  }
}

.theo2 {
  &:before,
  &:after {
    display: block;
  }

  &.open {
    &:before {
      content: "";
      position: absolute;
      bottom: 49px;
      right: 0px;
      transform: skew(-45deg);
      z-index: 4;
      width: 0;
      height: 0;
      border: 1.1rem solid transparent;
      border-bottom: none;
      border-top: 1.1rem solid var(--white, #FFFFFF);
      filter: drop-shadow(0 1mm 0.7mm rgba(15, 23, 42, 0.06));
      display: block;

      @media screen and (min-width: $desktop) {
        bottom: 66px;
      }
    }
    &:after {
      content: "";
      position: absolute;
      bottom: 48px;
      right: -4px;
      transform: skew(-45deg);
      z-index: 3;
      width: 0;
      height: 0;
      border: 1.3rem solid transparent;
      border-bottom: none;
      border-top: 1.3rem solid rgba(27, 31, 35, 0.15);
      filter: drop-shadow(0 1mm 0.7mm rgba(15, 23, 42, 0.06));
      display: block;

      @media screen and (min-width: $desktop) {
        bottom: 65px;
      }
    }
  }
}

.theo-info-card {
  .card {
    padding: 20px 24px;
  }
  .cta-card-description {
    font-size: 16px;
    color: var(--dark-blue, #0C354B);
  }
  .img-wrapper img {
    width: 64px;
  }
}

.theo-popup-container {
  position: fixed;
  left: 8px;
  bottom: 8px;
  z-index: 999;

  .theo-popup {
    -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
    color: var(--dark-blue, #0C354B);
    font-family: sofiaproblack, sans-serif;
    text-align: left;
    z-index: 4;
    box-shadow: 0 0 1px rgba(15, 23, 42, 0.06), 0 10px 15px -3px rgba(15, 23, 42, 0.1),
      0 4px 6px -2px rgba(15, 23, 42, 0.05);
    right: -32px;
    top: -12px;
    position: relative;
    width: 200px;
    border: 1px solid rgba(27, 31, 35, 0.15);

    @media screen and (min-width: $desktop) {
      width: 250px;
    }
  }

  .theo-popup-clickable {
    cursor: pointer;
  }

  .popup-expand-button {
    background-image: url("../../img/chevron-left.svg");
    appearance: none;
    background-color: var(--white, #FFFFFF);
    height: 24px;
    width: 24px;
    border: 2px solid var(--primary-blue, #68A8FB);
    background-repeat: no-repeat;
    border-radius: 100%;
    background-position: center;
    vertical-align: middle;
    position: absolute;
    right: -8px;
    cursor: pointer;
    z-index: 9;

    &.temporary-nudge {
      top: -10px;
    }
  }

  .popup-collapse-button {
    background-image: url("../../img/chevron-left.svg");
    appearance: none;
    background-color: var(--white, #FFFFFF);
    height: 12px;
    width: 12px;
    background-repeat: no-repeat;
    border-radius: 100%;
    background-position: center;
    vertical-align: middle;
    position: absolute;
    right: -8px;
    cursor: pointer;
    z-index: 9;

    transform: rotate(180deg);
    left: 91%;
    top: 4%;
  }

  .theo-popup-content-wrapper {
    font-family: "sofiaprobold", sans-serif;
    padding: 16px;
    max-height: 250px;
    overflow-y: auto;
    display: flex;
    min-width: 0;
    word-wrap: break-word;
    background-color: var(--white, #FFFFFF);
    background-clip: border-box;
    box-shadow: var(--light-gray, #E2E8F0) 0 1px 15px;
    border-radius: 4px;
    font-size: 11px;
    width: 100%;

    @media screen and (min-width: $desktop) {
      max-height: 310px;
      font-size: 13px;
      min-width: 150px;
    }
  }

  .expandable-content {
    min-height: 1px;
    flex: 1 1 auto;
    order: 1;

    span {
      color: var(--dark-gray, #3D5D6F);
    }
  }

  &.blue-border .theo-popup-content-wrapper {
    border: 2px solid var(--primary-blue, #68A8FB);
  }

  .summary-content {
    order: 2;
  }

  &.blue-border .theo {
    &:before {
      bottom: 50px;
      right: -37px;
      filter: none;
      border-width: 1.11rem;
      border-top-width: 1.13rem;
      transform: skew(0deg);
      @media screen and (min-width: $desktop) {
        bottom: 68px;
      }
    }
    &:after {
      display: block;
      bottom: 47px;
      border-top-color: var(--primary-blue, #68A8FB);
      transform: skew(0deg);
      @media screen and (min-width: $desktop) {
        bottom: 64px;
      }
    }
  }
}

.hover-higher {
  .theo-popup-container {
    bottom: 90px;
  }
}

.theo-dialogue {
  width: 880px;

  .speech-balloon {
    margin: auto;
    width: 774px;
    height: 257px;
    background-repeat: no-repeat;

    .section-title {
      margin: 0;
      padding-top: 32px;
      padding-bottom: 12px;
    }

    .section-content {
      padding: 0 90px;
    }
  }

  .image-theo {
    float: right;
    z-index: -1;
  }
}

.theo-info-card {
  .card {
    padding: 20px 24px;
  }
  .cta-card-description {
    font-size: 16px;
    color: var(--dark-blue, #0C354B);
  }
  .img-wrapper img {
    width: 64px;
  }
}
