.results-winner-card,
.cbr-winner-card {
  .card {
    padding: 40px;
    border: 2px solid var(--primary-red, #FD7B56);

    .winner-title,
    .plan-with-currency,
    .right-side-title {
      font-family: sofiaproblack, sans-serif;
    }

    .winner-title {
      color: var(--primary-red, #FD7B56);
      font-size: 14px;
      margin-top: 20px;

      @media (min-width: $mediumScreen) {
        font-size: 18px;
      }
    }

    .plan-with-currency {
      font-size: 24px;

      @media (min-width: $mediumScreen) {
        font-size: 48px;
      }
    }

    .plan-name,
    .right-side-title {
      font-family: sofiaprobold, sans-serif;
    }

    .plan-name {
      color: var(--mid-gray, #9EAEB7);
      font-size: 24px;
    }

    .right-side-title {
      font-size: 13px;

      @media (min-width: $mediumScreen) {
        font-size: 19px;
      }
    }

    .right-side-subtitle {
      color: var(--dark-gray, #3D5D6F);
      font-size: 17px;
    }

    .key-factors-container {
      margin-top: 40px;
    }

    .icon-family {
      width: 48px;

      @media (min-width: $mediumScreen) {
        width: 57px;
      }
    }

    .icon-checklist {
      width: 48px;

      @media (min-width: $mediumScreen) {
        width: 60px;
      }
    }

    .icon-winner-card img {
      width: 48px;

      @media (min-width: $mediumScreen) {
        width: auto;
      }
    }

    .plan-breakdown {
      h2 {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 0;
      }

      .breakdown-total-cost {
        p:first-child {
          color: var(--orange, #F39D2A);
          font-size: 36px;
          font-family: sofiaprobold, sans-serif;
          line-height: 48.6px;
          margin: 0;
        }

        p:last-child {
          color: opacify(#9EAEB7, 0.7); // this should be the --mid-gray color var but that doesn't work with SASS mixins
          font-size: 14px;
        }
      }

      .breakdown-graph {
        p {
          margin: 0;
        }

        .graph-hbar {
          border-radius: 4px;
          height: 24px;
          background-color: var(--yellow, #FECB60);
          overflow: hidden;
          position: relative;

          .current-score {
            position: absolute;
            z-index: 2;
            border-radius: 4px;
            height: inherit;
            background-color: var(--orange, #F39D2A);
          }
        }

        .graph-premium {
          p {
            font-family: sofiaprobold, sans-serif;

            &:first-child::before {
              display: inline-block;
              content: "";
              width: 8px;
              height: 8px;
              padding: 5px;
              border-radius: 100%;
              background-color: var(--orange, #F39D2A);
              margin: -2px 5px 0 0;
            }
          }
        }

        .graph-pocket-cost {
          p {
            font-family: sofiaprobold, sans-serif;
            color: transparentize(#0C354B, 0.2);

            &:first-child::before {
              display: inline-block;
              content: "";
              width: 8px;
              height: 8px;
              padding: 5px;
              border-radius: 100%;
              background-color: var(--yellow, #FECB60);
              margin: -2px 5px 0 0;
            }
          }
        }
      }
    }

    .plan-callouts {
      border-top: 1px solid var(--bs-black);

      .callout-header {
        padding: 20px 0 0;

        p {
          font-family: sofiapromedium, sans-serif;
          font-weight: 500;
          color: #0C354B;
          filter: contrast(1.4);
          text-align: left;
          width: 80%;

          span {
            font-family: sofiaprobold, sans-serif;
          }
        }
      }

      .callouts {
        margin-top: 10px;
        display: none;

        &.is-open {
          display: block;
        }

        .splide {
          overflow: auto;
        }

        .callout-item {
          color: #0C354B;
          font-size: 16px;
          padding: 20px 0;
          margin: 0 0 10px;
          filter: contrast(1.4);

          h3 {
            font-family: sofiaprobold, sans-serif;
            font-size: 16px;
            margin-bottom: 25px;
            filter: contrast(1.4);
          }

          p {
            font-family: sofiapromedium, sans-serif;
            font-weight: 500;
            color: #0C354B;
            margin-bottom: 25px;
            filter: contrast(1.4);
          }

          div {
            filter: contrast(1.4);
          }

          ul {
            list-style: disc;
            margin-top: -20px;

            li {
              font-family: sofiapromedium, sans-serif;
              color: #0C354B;
              text-align: left;
            }
          }
        }

        .callout-pagination {
          border-top: 1px solid var(--bs-black);
          position: relative;
          padding: 20px 0 0;
          display: flex;

          .callout-pagination-btn {
            background: #D9D9D9;

            &.is-active {
              background: #FD7B56;
              transform: scale(1);
            }
          }
        }

        .pagination-hidden {
          display: none;
        }
      }
    }
  }

  @media screen and(max-width: $mediumScreen) {
    text-align: center;
    padding: 0;
  }

  .text-description-wrapper {
    margin-left: 0;

    @media (min-width: 1440px) {
      margin-left: -32px;
    }

    @media (min-width: 2560px) {
      margin-left: -105px;
    }
  }

  .winner-title-wrapper {
    margin-left: 0;

    .plan-start {
      font-family: sofiaprobold, sans-serif;
    }
  }
}

.cbr-winner-card .winner-title-wrapper {
  @media (min-width: $largeScreen) {
    padding-left: 32px;
  }

  @media (min-width: 1440px) {
    padding-left: 0;
  }
}