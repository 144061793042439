.page-get-started {
  color: var(--dark-blue, #0C354B);

  .next:after img {
    padding-top: 15px;
  }

  .left-side {
    .text-container {
      margin-left: 0;

      @media screen and (min-width: $largeScreen) {
        margin-left: 25%;
      }

      .title {
        margin-bottom: 55px;
      }

      .subtitle {
        font-family: sofiaproblack, serif;
      }
    }
  }

  .right-side {
    text-align: right;

    .bg-large-dot-right {
      right: 0;
      top: 80px;
    }

    .graphic {
      position: relative;
      margin-top: 23px;
      width: 40vw;
    }
  }

  .large-toggle-btn {
    margin-bottom: 70px;
  }

  .large-number {
    margin-top: -45px;
    font-size: 104px;
    font-family: sofiaproblack, serif;
  }

  .row {
    &:nth-of-type(2),
    &:nth-of-type(3) {
      padding-top: 35px;
    }
  }

  .action-button {
    width: 100%;
    margin-top: 70px;

    @media screen and (min-width: $largeScreen) {
      width: 515px;
    }
  }

  .bg-large-dot-bottom-right {
    margin-right: 0;
    right: 0;
  }

  .bg-dot-bottom-left {
    margin-left: 0;
    margin-bottom: 0;
    padding-top: 175px;
    padding-bottom: 0;
    left: 0;
  }

  .cards-container {
    width: 100%;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto;

    @media screen and (min-width: $largeScreen) {
      width: 100%;
      padding-right: 0;
      padding-left: 0;
      margin-right: auto;
      margin-left: auto;
    }
  }

  .subtext {
    color: var(--dark-gray, #3D5D6F);
  }
}
