.speech-bubble {
  background: var(--white, #FFFFFF);
  border-radius: 25px;
  padding: 32px;

  @media screen and (max-width: $smallScreen) {
    margin: auto;
  }

  .section-title {
    margin: 0;
    padding-bottom: 12px;
    font-size: 24px;
    line-height: 1.5;

    @media screen and(min-width: $largeScreen) {
      font-size: 36px;
    }
  }

  .section-content {
    padding: 0 90px;
    max-width: 720px;
    margin: 0 auto;

    .bold-text {
      color: var(--dark-gray, #3D5D6F);
    }

    @media screen and(max-width: $largeScreen) {
      padding: 0 30px;
    }

    p {
      @media screen and(max-width: $largeScreen) {
        font-size: 14px;
      }
    }
  }
}

.speech-bubble cite {
  bottom: -3rem;
  left: 50px;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.5px;
  color: var(--white, #FFFFFF);
}

.image-theo {
  background-image: url('../../img/theo-left.png');
  position: relative;
  width: 100px;
  background-repeat: no-repeat;
  height: 64px;
  top: 24px;
}

.pointer {
  content: url('../../img/speech-balloon-pointer.png');
  position: absolute;
  left: -75px;
  top: -24px;
}
