.coverage-plans-details {
  margin-bottom: 32px;

  .coverage-plans-details-table {
    max-width: 100%;
    overflow-x: hidden;

    @media screen and (max-width: $smallScreen) {
      overflow-x: scroll;
    }
  }

  .coverage-plans-details-table::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 6px;
  }

  .coverage-plans-details-table::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: var(--light-blue, #CAE1FF);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  .coverage-plans-details-title {
    font-family: sofiaprobold;
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 26px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--dark-blue, #0C354B);
  }

  table {
    border-collapse: separate;
    border-spacing: 0px 8px;
    width: 100%;
  }

  tr {
    background: var(--off-white, #F6FAFF);
    height: 48px;
  }

  th {
    font-family: sofiaprobold;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    text-transform: uppercase;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--dark-gray, #3D5D6F);
  }

  td {
    text-align: right;
    font-style: normal;
    font-size: 16px;
    font-weight: 500px;
    line-height: 24px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    padding-right: 25px;
    padding-left: 16px;
  }

  .top-row {
    background: none;

    .tier-title {
      padding-right: 25px;
    }
  }

  .bottom-row {
    background: var(--light-gray, #E2E8F0);
  }

  .first-column {
    text-align: left;
  }

  .row-header {
    margin-left: 8px;
    font-family: sofiaprobold;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 18px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--dark-blue, #0C354B);
  }

  .persons-clarifier {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--dark-blue, #0C354B);
  }

  .detail-values {
    display: inline-block;

    span {
      font-family: sofiaprobold;
      font-style: normal;
      font-weight: 900;
      font-size: 16px;
      line-height: 24px;
      font-feature-settings: 'tnum' on, 'lnum' on;
      color: var(--dark-blue, #0C354B);
    }
  }

  .column-toggle-row {
    text-align: right;
    @media screen and (max-width: $smallScreen) {
      display: none;
    }

    img {
      width: 458px;
      height: 8px;
    }

    img:hover {
      cursor: pointer;
    }
  }

  .space {
    background: var(--white, #FFFFFF);
    padding: 0px;
    width: 8px;
  }

  table.tiered-table {
    width: max-content;
    white-space: nowrap;

    tr {
      max-height: 48px;
    }

    .first-col-dental {
      width: max-content;
    }

    .first-col-vision {
      width: 330px;
    }

    .second-column-see-right {
      max-width: 250px;
      overflow: hidden;
    }

    .shrink-dental {
      max-width: 60px;
    }

    .shrink-vision {
      max-width: 150px;
    }

    .inner-second-col-see-right {
      float: right;
    }
  }

  table.tiered-table.see-right {
    width: 100%;
  }
}
