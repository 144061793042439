.popup-content {
  border-radius: 4px;
  color: var(--dark-blue, #0C354B);
  padding: 25px;

  .modal {
    font-size: 12px;
    display: contents;

    .header {
      font-family: sofiaprobold, sans-serif;
      font-size: 24px;
      text-align: left;
      padding: 5px;
    }

    .content {
      font-size: 16px;
    }

    .header, .content {
      padding-right: 50px;
    }

    .actions {
      width: 100%;
      padding: 10px 5px;
      text-align: right;

      button {
        font-size: 20px;
        letter-spacing: 0;
      }
    }

    .close {
      color: var(--dark-gray, #3D5D6F);
      cursor: pointer;
      position: absolute;
      display: block;
      padding-right: 24px;
      margin-top: -20px;
      right: 0;
      font-size: 35px;
      background: transparent;
    }
  }
}

#SOIDIV_MyHealthMathDemo {
  min-width: 0;

  iframe#SOI_MyHealthMathDemo {
    min-width: 0 !important;
  }
}
