.results-plan-breakdown-card {
  color: var(--dark-blue, #0C354B);

  div:nth-child(3) {
    margin-bottom: 0;
  }

  .card {
    padding: 40px;

    .large-number {
      color: var(--orange, #F39D2A);
      font-size: 36px;
      font-family: sofiaprobold, sans-serif;
      margin-top: -15px;
    }
  }

  .card-description {
    .icon {
      text-align: left;

      img {
        width: 64px;
        margin-top: -5px;
      }
    }

    .title {
      font-family: sofiaprobold, sans-serif;
      font-size: 24px;
      color: var(--dark-blue, #0C354B);
    }

    .subtitle {
      color: var(--dark-gray, #3D5D6F);
      line-height: 16px;
    }
  }

  .card-section-container {
    margin-bottom: 60px;

    .progress-bar {
      background-color: var(--orange, #F39D2A);
    }
  }

  .total-costs-desc {
    color: opacify(#9EAEB7, 0.7); // this should be the --mid-gray color var but that doesn't work with SASS mixins
    font-size: 14px;
  }

  .bullet-subtitle {
    font-size: 18px;
  }

  .bullet-title, .bullet-subtitle {
    font-family: sofiaprobold, sans-serif;
  }

  .bg-orange, .bg-yellow, .bullet-title {
    margin-top: 15px;
    display: inline-block;
  }

  .currency {
    font-size: 24px;
  }

  .currency, .currency-subtitle {
    font-family: sofiaprobold, sans-serif;

    @media (min-width: $mediumScreen) {
      text-align: left;
    }
  }

  .currency-subtitle {
    font-size: 14px;

    @media (min-width: $mediumScreen) {
      font-size: 16px;
    }
  }

  .currency span {
    font-family: sofiapromedium, sans-serif;
    font-size: 11px;
  }

  .currency-subtitle, .currency span {
    color: var(--mid-gray, #9EAEB7);
  }

  .title-text-wrapper {
    margin-left: 0;

    @media (min-width: $mediumScreen) {
      margin-left: -20px;
    }
    @media (min-width: $largeScreen) {
      margin-left: 12px;
    }
  }

  .total-cost-amount-wrapper {
    margin-bottom: 28px;
    @media (min-width: $mediumScreen) {
      margin-bottom: 0;
    }
  }

  .progress-bar-wrapper {
    margin-left: 0;

    @media (min-width: $mediumScreen) {
      margin-left: -24px;
    }

    .progress {
      flex-direction: column-reverse;
      height: 300px;
      position: relative;
      width: 16px;

      @media (min-width: $mediumScreen) {
        flex-direction: row;
        height: 1rem;
        width: auto;
      }

      .progress-bar {
        position: absolute;

        @media (max-width: $mediumScreen) {
          &.progress-bar-plain {
            left: auto;
            bottom: 0;
          }
          &.progress-bar-striped {
            left: auto;
            top: 0;
          }
          height: auto;
          width: 100% !important;
        }

        @media (min-width: $mediumScreen) {
          width: auto;
          height: 16px !important;

          &.progress-bar-plain {
            left: 0;
            bottom: auto;
          }

          &.progress-bar-striped {
            right: 0;
            top: auto;
          }
        }
      }
    }
  }
}
