//BACKGROUND STYLING
.bg-large-dot-right, .bg-large-dot-bottom-right, .bg-dot-bottom-left {
  position: absolute;
}

.bg-large-dot-right {
  content: url('../../img/bg-elipse-middle-right.png');
}

.bg-large-dot-bottom-right {
  content: url('../../img/bg-elipse-bottom-right.png');
}

.bg-dot-bottom-left {
  content: url('../../img/bg-elipse-bottom-left.png');
}

.date-of-birth-picker {
  display: flex;
  align-items: center;
  background-color: var(--white, #FFFFFF);

  &.valid {
    border-color: var(--light-black, #0F172A) !important;
  }
}

.date-of-birth-picker input {
  display: inline;
  width: 28px;
  flex: 1;
  flex-shrink: 0;
  text-align: left;
  border: none;
  outline: none;
  box-shadow: none;
  background-color: var(--white, #FFFFFF);

  &.year {
    flex: 2;
    flex-shrink: 0;
    width: 50px;
  }

  &:focus-visible {
    border: none;
  }
}

.form {
  padding-bottom: 20px;
}

.form-title, .question-title {
  color: var(--dark-blue, #0C354B);
  font-family: sofiaproblack, sans-serif;
  font-size: 36px;
  font-weight: 900;
  text-align: center;
  margin: 0 auto 50px;
  max-width: 80vw;
}

.question-title {
  margin-top: 120px;
}

.question-title small {
  font-family: sofiapromedium, sans-serif;
  font-size: 12px;
  font-weight: 400;
}

.option-title {
  font-family: sofiaprobold, serif;
  font-size: 20px;
  color: var(--dark-blue, #0C354B);

  @media screen and (max-width: $smallScreen) {
    font-size: 16px;
  }
}

.option-content {
  width: 100%;
  background: var(--white, #FFFFFF);
  border-left: 2px solid var(--primary-blue, #68A8FB);
  border-right: 2px solid var(--primary-blue, #68A8FB);
  border-bottom: 2px solid var(--primary-blue, #68A8FB);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.option-content-with-popup {
  display: block;
  background: white;
  padding-top: 8px;
  margin-top: -2px;
  border-bottom: 2px solid var(--primary-blue, #68A8FB);
  border-left: 2px solid var(--primary-blue, #68A8FB);
  border-right: 2px solid var(--primary-blue, #68A8FB);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

input:not([value=""]):not(:focus):valid {
  border-color: var(--light-black, #0F172A);
}
