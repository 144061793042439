.results-compare-plan-card {
  .card {
    padding: 40px;

    @media screen and(max-width: $smallScreen) {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  .plan-text {
    margin-left: 2.5%;
    margin-bottom: 20px;
    margin-top: 10px;
    font-size: 11px;
    line-height: 16.5px;

    .plan-name {
      font-weight: 800;
    }
  }

  .chart-description {
    margin-top: 0;

    .hover-plan-tip {
      padding-top: 14px;
      display: flex;
      align-items: center;
      justify-content: end;
      margin-bottom: 20px;

      p {
        color: #FD7B56;
        font-size: 10px;
        margin-bottom: 0 !important;
      }

      img {
        padding-right: 5px;
      }
    }

    .icon {
      text-align: left;
    }

    .title {
      font-family: sofiaprobold, sans-serif;
      font-size: 24px;
      color: var(--dark-blue, #0C354B);
    }

    .subtitle {
      color: var(--dark-gray, #3D5D6F);
      line-height: 16px;
    }
  }

  .chart-container {
    position: relative;
    width: 100%;
    margin-top: 35px;
    padding-left: 10px;
    padding-right: 10px;
    height: 250px;

    @media screen and(min-width: $largeScreen) {
      height: 100%;
    }

    @media screen and(max-width: $smallScreen) {
      height: fit-content;
    }
  }

  .chart-legend-breakdown-btn-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: 4.8%;

    @media(min-width: 640px) {
      margin-left: 8.8%;
    }

    @media(min-width: 1024px) {
      margin-left: 7.2%;
    }

    @media(min-width: 1280px) {
      margin-left: 5.8%;
    }

    @media(min-width: 1536px) {
      margin-left: 4.8%;
    }

    @media(max-width: 1566px) {
      margin-top: 30px;
    }

    @media screen and (max-width: 425px) {
      margin: 30px 10px 0;
    }

    .chart-legend-container {
      display: grid;
      grid-template-columns: repeat(3, auto);
      flex-wrap: wrap;
      align-items: center;
      gap: 10px 40px;

      .legend {
        display: flex;
        gap: 6px;
        flex: auto;

        p {
          font-size: 11px;
          line-height: 16.5px;
          margin-bottom: 0;
        }

        .legend-color {
          border-radius: 4px;
          background-color: #10B981;
          min-width: 15px;
          height: 15px;
        }
      }
    }

    .breakdown-btn-container {
      @media (max-width: 1279px) {
        margin: 25px auto 0;
      }
    }
  }

  .buttons-container {
    margin-top: -50px;
    margin-bottom: -40px;
    padding-top: 25px;
    text-align: -webkit-center;
    background: var(--white, #FFFFFF);
    z-index: 1;

    @media screen and(min-width: $largeScreen) {
      margin-top: -70px;
    }

    .button-title,
    .button-subtitle {
      display: block;
      color: var(--mid-gray, #9EAEB7);
    }

    button {
      display: block;
      background: none;
      letter-spacing: 0;
      border-bottom: 7px solid transparent;
      border-radius: 0;
      padding: 0;

      &.active {
        border-bottom: 7px solid var(--primary-red, #FD7B56);

        .button-title,
        .button-subtitle {
          color: var(--dark-blue, #0C354B);
        }
      }
    }

    .button-title {
      font-family: sofiaprobold, sans-serif;
      font-size: 18px;
    }

    .button-subtitle {
      font-size: 14px;
      padding-bottom: 25px;
    }

    @media screen and(max-width: $smallScreen) {

      .button-title,
      .button-subtitle {
        font-size: 12px;
      }
    }
  }

  .tabs-container {
    margin-top: -40px;
    margin-left: -40px;
    margin-right: -40px;
    padding-bottom: 20px;
    text-align: -webkit-center;
    background: var(--white, #FFFFFF);
    z-index: 1;

    @media screen and (max-width: 768px) {
      margin-left: -5px;
      margin-right: -5px;
      border-radius: 8px;
    }

    .button-title {
      display: block;
      color: var(--mid-gray, #9EAEB7);
    }

    button {
      background-color: #E2E8F0 !important;
      width: 100% !important;
      display: block;
      background: none;
      letter-spacing: 0;
      // border-bottom: 7px solid transparent;
      border-radius: 0;
      padding: 10px 0px !important;

      &.active {
        padding: 10px 0px !important;
        background-color: #FFFFFF !important;

        .button-title {
          color: var(--dark-blue, #0C354B);
        }
      }
    }

    .button-title {
      font-family: sofiaprobold, sans-serif;
      font-size: 18px;
    }

    .button-subtitle {
      font-size: 14px;
      padding-bottom: 25px;
    }

    @media screen and(max-width: $smallScreen) {

      .button-title,
      .button-subtitle {
        font-size: 11px;
      }
    }
  }

  .toogle-container {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 15px;

    p {
      margin-bottom: 0 !important;
      font-size: 14px;
      font-weight: 800;
    }
  }


  .cbr-chart-wrapper {
    background: var(--white, #FFFFFF);
    z-index: 10;
  }

  .title-text-wrapper {
    margin-left: 0;

    @media (min-width: 1400px) {
      margin-left: -20px;
    }
  }
}