:root {
  --mhm-dd-logo: url("../../img/decisiondoc_logo.png");
  --dd-logo: url("../../img/decisiondoc_logo.png");
}

.navbar {
  height: auto;
  font-size: 16px;
  background-color: var(--white, #FFFFFF);
  z-index: 101;
  @media screen and (min-width: $largeScreen) {
    height: 82px;
  }

  .navbar-nav {
    width: 100%;
  }

  .navbar-home-button {
    margin-left: 0;
    width: 180px;
    height: 50px;
    display: block;
    @media screen and (min-width: $largeScreen) {
      margin-left: 24px;
    }
  }

  .navbar-default-logo {
    background: var(--mhm-dd-logo, url("../../img/decisiondoc_logo.png")) no-repeat;
    background-size: contain;
  }

  .navbar-sherpa-logo {
    background: var(--dd-logo, url("../../img/decisiondoc_logo.png")) no-repeat;
    background-size: contain;
    background-position: center;
  }

  .navbar-custom-logo {
    background-size: contain;
    background-position: left center;
    background-repeat: no-repeat;
  }

  .navbar-logo-778 {
    background: url("../../img/logo_idexx.bmp") no-repeat;
    background-size: contain;
    background-position: left center;
  }

  .navbar-logo-794 {
    background: url("../../img/logo_orion.png") no-repeat;
    background-size: contain;
    background-position: left center;
  }

  .navbar-logo-798 {
    background: url("../../img/logo_swirl.png") no-repeat;
    background-size: contain;
    background-position: left center;
  }

  .navbar-logo-819 {
    background: url("../../img/logo_tti.jpg") no-repeat;
    background-size: contain;
    background-position: left center;
  }

  .navbar-dropdown {
    &.next {
      &:after {
        margin-left: 7px;
        font-family: 'mhmchevrondown', sans-serif;
        color: var(--primary-red, #FD7B56);
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        font-size: 18px;
        text-transform: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        display: inline-block;
        content: '\e900';
        vertical-align: middle;
      }
    }
  }

  .navbar-right {
    .auth-link {
      margin-left: 0;
      @media screen and (min-width: $largeScreen) {
        margin-left: 24px;
      }
    }
    .help-center {
      cursor: pointer;
      color: var(--dark-blue, #0C354B);
      font-weight: 900;

      &.authenticated .dropdown-menu {
        left: -26px;
      }
    }
    .glossary-link {
      cursor: pointer;
      span {
        font-weight: 900;
        font-size: 16px;
        color: var(--dark-blue, #0C354B);
      }
    }
    .schedule-call {
      cursor: pointer;
    }
  }
  &.navbar-light .navbar-nav .nav-link {
    color: var(--dark-blue, #0C354B);
  }
  &.navbar-light .navbar-nav .show > .nav-link,
  .navbar-light .navbar-nav .nav-link.active {
    color: var(--dark-blue, #0C354B);
  }
  .dropdown-item.active,
  .dropdown-item:active {
    background-color: var(--light-gray, #E2E8F0);
    color: var(--dark-blue, #0C354B);
  }
  .dropdown-toggle::after {
    margin-left: 7px;
    font-family: 'mhmchevrondown', sans-serif;
    color: var(--primary-red, #FD7B56);
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    font-size: 18px;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    content: '\e900';
    vertical-align: middle;
    border: none;
  }

  .navbar-toggler {
    border: none;
    &.navbar-toggler:focus {
      box-shadow: none;
    }
  }

  .dropdown-menu {
    border: none;

    @media screen and (min-width: $largeScreen) {
      border: 1px solid rgba(0, 0, 0, 0.15);
    }
  }

  .navbar-collapse .navbarScroll.navbar-nav {
    padding-top: 16px;

    @media screen and (min-width: $largeScreen) {
      padding-top: 0;
    }
  }
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: var(--pale-white, #FAFAFA);
  background-color: #e66c4a !important;
}
