.learn-more-education-card-container {
  max-width: 958px;
  width: 100%;
}

.learn-more-carousel {
  background: var(--white, #FFFFFF);
  border: 2px solid var(--light-gray, #E2E8F0);
  box-sizing: border-box;
  box-shadow: 0px 0px 1px rgba(15, 23, 42, 0.06),
    0px 4px 6px rgba(15, 23, 42, 0.1), 0px 2px 4px rgba(15, 23, 42, 0.06);
  border-radius: 8px;
  width: 100%;
  height: 100%;

  .card {
    height: 100%;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .card-container {
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 1;
  }

  .card-text-row {
    margin-top: 16px;
    margin-bottom: 48px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .card-title {
    font-family: sofiaprobold;
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    text-align: center;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--dark-blue, #0C354B);
    margin-bottom: 16px;
  }

  .card-desciption {
    width: 802px;
    height: 24px;
    font-family: sofiaprobold;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--dark-gray, #3D5D6F);
  }

  .learn-more-card-theo {
    width: 64px;
    height: 39px;
    padding: 0px;
  }

  .no-thanks-button {
    font-family: sofiaprobold;
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--light-black, #0F172A);
    background: none;
    margin-right: 48px;
    padding: 0px;
    letter-spacing: normal;

    @media screen and (max-width: $smallScreen) {
      margin-right: 24px;
    }
  }

  .next-button {
    width: 221px;
    height: 40px;
    background: var(--primary-red, #FD7B56);
    border-radius: 98px;
    font-family: sofiaprobold;
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--white, #FFFFFF);
    padding: 0px;
    letter-spacing: normal;

    @media screen and (max-width: $smallScreen) {
      padding: 15px;
    }
  }

  .overlap-row-behind {
    position: absolute;
    z-index: 2;
  }

  .overlap-row-in-front {
    z-index: 3;
    background-color: var(--white, #FFFFFF);
    border-top: solid thin var(--light-gray, #E2E8F0);
    margin-top: 205px;
  }

  .carousel-dot-column-hide {
    display: none;
  }

  .learn-more-card-img {
    width: 263px;
    height: 273px;
  }

  .theo-pushed-center {
    width: 64px;
    height: 39px;
    margin-bottom: 67px;

    @media screen and (max-width: $smallScreen) {
      display: none;
    }
  }

  .carousel-dot {
    width: 16px;
    height: 16px;
    margin-right: 21px;
  }

  .carousel-dot:hover {
    cursor: pointer;
  }

  .hide-learn-more-card-img {
    display: none;
  }

  .all-done-button {
    width: 221px;
    height: 40px;
    background: var(--primary-red, #FD7B56);
    border-radius: 98px;
    font-family: sofiaprobold;
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--white, #FFFFFF);
    letter-spacing: normal;

    @media screen and (max-width: $smallScreen) {
      width: 40px;
    }
  }

  .learn-more-card-buttons {
    padding-right: 40px;
  }
}

.collapsed-learn-more-card {
  width: 100%;
  min-height: 88px;
  background: var(--white, #FFFFFF);
  border: 1px solid var(--light-gray, #E2E8F0);
  box-sizing: border-box;
  box-shadow: 0px 0px 1px rgba(15, 23, 42, 0.06),
    0px 4px 6px rgba(15, 23, 42, 0.1), 0px 2px 4px rgba(15, 23, 42, 0.06);
  border-radius: 8px;

  .card {
    height: 100%;
    min-height: 88px;
    width: 100%;
  }

  .learn-more-card-theo {
    width: 64px;
    height: 39px;
    padding: 0px;
    margin-right: -24px;
  }

  .collapsed-card-text {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--dark-blue, #0C354B);

    @media screen and (max-width: $smallScreen) {
      max-width: fit-content;
    }
  }

  .collapsed-card-text-bold {
    font-family: sofiaprobold;
    font-style: normal;
    font-weight: 900;
  }

  .learn-more-button {
    height: 24px;
    font-family: sofiaprobold;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--primary-red, #FD7B56);
    background-color: transparent;
    text-align: center;
    padding: 0px;
    margin-right: 33px;
    letter-spacing: normal;
  }

  .collapsed-card-col {
    padding: 0px;
    min-width: fit-content;
  }
}

.hide-card {
  display: none;
}

.hide-collapsed {
  display: none;
}
