:root {
  --btn-primary-hover-color: #e66c4a;
  --btn-primary-hover-border-color: #fd8867;
  --btn-primary-disabled-color: #FFAB93;
}

button {
  display: inline-flex;
  background-color: var(--primary-red, #FD7B56);
  color: var(--white, #FFFFFF);
  padding: 12px 32px;
  font-size: 12px;
  font-family: sofiapromedium, sans-serif;
  font-weight: 900;
  border: 0;
  border-radius: 90px;
  outline: none;
  cursor: pointer;
  letter-spacing: 1.4px;
  align-items: center;
  justify-content: center;

  &:hover {
    color: var(--pale-white, #FAFAFA);
  }

  &.outline {
    background-color: var(--white, #FFFFFF);
    border: 4px solid var(--primary-red, #FD7B56);
    box-shadow: none;

    &:hover,
    &:focus {
      background-color: var(--btn-primary-hover-color, #e66c4a);
      outline: none;
      box-shadow: none;
      color: var(--pale-white, #FAFAFA);
    }
  }
}

button,
a {
  &.btn-primary {
    &:disabled {
      background-color: var(--primary-red, #FD7B56);
      pointer-events: none;
    }
    &:hover {
      color: var(--pale-white, #FAFAFA);
      background-color: var(--btn-primary-hover-color, #e66c4a) !important;
      border-color: var(--btn-primary-hover-border-color, #fd8867);
    }
  }
  &.btn-secondary {
    &:disabled {
      background-color: inherit !important;
      pointer-events: none;
    }
  }

  &.small {
    font-size: 10px;
    padding: 6px 18px;
  }

  &.big {
    padding: 12px 24px;
    width: 240px;
    font-size: 20px;
  }

  &.medium {
    width: 180px;
    height: 48px;
  }

  &.round {
    border-radius: 90px;
  }

  &.text-dark {
    color: var(--dark-blue, #0C354B);
  }

  &.next {
    display: inline-flex;
    &:after {
      font-family: 'mhmarrowforward';
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 24px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      display: inline-block;
      content: '\e900';
      width: 18px;
      height: 22px;
      margin-left: 15px;
    }
  }

  &.previous {
    &:before {
      display: inline-block;
      content: url('../../img/right-arrow-white.svg');
      width: 20px;
      height: 22px;
      margin-right: 15px;
      transform: rotate(180deg);
    }
  }
}

.action-button {
  font-family: sofiaprobold;
  letter-spacing: 0;
  display: inline-block;
  width: 360px;
  height: 64px;
  line-height: 54px;
  border-radius: 90px;
  font-size: 20px;
  color: var(--white, #FFFFFF);
}

.action-button.outline {
  color: var(--dark-blue, #0C354B);
  line-height: normal;
}

.bottom-btn-wrapper {
  margin-top: 108px;
  margin-bottom: 82px;

  .btn-continue {
    margin-left: 24px;
  }

  .action-button {
    width: 264px;
  }

  .back-btn-arrow {
    &:before {
      font-family: 'mhmarrowback';
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      display: inline-block;
      content: '\e900';
      width: 18px;
      height: 22px;
      margin-right: 15px;
    }

    &:hover {
      &:before {
        color: var(--pale-white, #FAFAFA);
      }
    }
  }

  .btn-primary {
    &:focus,
    &:hover,
    &:active {
      color: var(--white, #FFFFFF);
      background-color: var(--btn-primary-hover-color, #e66c4a) !important;
    }
    &:disabled {
      background-color: var(--btn-primary-disabled-color, #FFAB93);
      border-color: var(--btn-primary-disabled-color, #FFAB93);
      color: var(--white, #FFFFFF);
    }
  }
}

@media screen and (max-width: $smallScreen) {
  .bottom-btn-wrapper {
    margin-top: 80px;
    text-align: center;

    button {
      display: block;
      margin: auto;
    }

    .btn-continue {
      margin-top: 24px;
      margin-left: auto;
    }
  }
}
