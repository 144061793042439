.glossary-page {
  .glossary-header {
    margin-bottom: 64px;
    .title {
      margin-top: 44px;
      color: var(--dark-blue, #0C354B);

      h1 {
        font-size: 36px;
        font-weight: 900;

        @media (min-width: $mediumScreen) {
          font-size: 48px;
        }
      }
    }
  }

  .glossary-section {
    margin-bottom: 64px;

    h2 {
      font-size: 36px;
      font-weight: 900;
      color: var(--dark-blue, #0C354B);
      max-width: 1026px;
      width: 100%;
      margin: 0 auto 29px;
    }
  }
}

.glossary-definition-card {
  background-color: var(--white, #FFFFFF);
  max-width: 1026px;
  width: 100%;
  padding: 26px 29px;
  min-height: 133px;
  margin: 0 auto 24px;

  &:last-of-type {
    margin-bottom: 0;
  }

  .terminology {
    color: var(--dark-blue, #0C354B);
    margin-bottom: 8px;
    h6 {
      font-size: 14px;
      font-weight: 900;

      @media (min-width: $mediumScreen) {
        font-size: 18px;
      }
    }
  }
  .definition {
    color: var(--dark-gray, #3D5D6F);
    font-size: 14px;
    line-height: 18px;
    max-width: 800px;
    width: 100%;
    text-align: justify;
  }
}
