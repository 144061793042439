.coverage-plans {
  width: 100%;

  .coverage-plans-container {
    max-width: 950px;
    background: var(--white, #FFFFFF);
    box-shadow: 0px 0px 1px rgba(15, 23, 42, 0.06),
      0px 4px 6px -1px rgba(15, 23, 42, 0.1),
      0px 2px 4px -1px rgba(15, 23, 42, 0.06);
    border-radius: 8px;
    margin-top: 48px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 20px;
  }

  .coverage-plans-section {
    margin-top: 24px;
    padding-left: 20px;
  }

  .coverage-plans-title-row {
    min-height: 121px;

    .plan-img-title-col {
      // min-width: fit-content;
      min-width: fit-content;
    }

    .plan-img-inner-col {
      @media screen and (max-width: $smallScreen) {
        display: revert !important;
      }
    }

    .coverage-plan-icon {
      height: 80px;
      margin-right: 20px;
    }

    .coverage-plans-title-name {
      font-family: sofiaprobold;
      font-style: normal;
      font-weight: 900;
      font-size: 36px;
      line-height: 135%;
      font-feature-settings: 'tnum' on, 'lnum' on;
      color: var(--dark-blue, #0C354B);
      margin-bottom: 7px;
    }

    .coverage-plans-title-type {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 18px;
      text-transform: uppercase;
      font-feature-settings: 'tnum' on, 'lnum' on;
      color: var(--dark-gray, #3D5D6F);
    }

    .family-plan-icon {
      height: 38px;
      margin-right: 8px;
    }

    .family-plan-text {
      font-family: sofiaprobold;
      font-style: normal;
      font-weight: 900;
      font-size: 16px;
      line-height: 24px;
      font-feature-settings: 'tnum' on, 'lnum' on;
      color: var(--dark-blue, #0C354B);
      margin-bottom: -4px;
    }

    .family-members-text {
      font-style: normal;
      font-size: 14px;
      line-height: 21px;
      font-feature-settings: 'tnum' on, 'lnum' on;
      color: var(--dark-gray, #3D5D6F);
    }
  }

  .coverage-plans-premium-row {
    box-sizing: border-box;
    background: var(--white, #FFFFFF);
    border: 1px solid var(--light-blue, #CAE1FF);
    border-radius: 8px;
    padding-top: 22px;
    padding-bottom: 22px;

    .coverage-plans-premium-title {
      font-family: sofiaprobold;
      font-style: normal;
      font-weight: 900;
      font-size: 18px;
      line-height: 136.5%;
      font-feature-settings: 'tnum' on, 'lnum' on;
      color: var(--dark-gray, #3D5D6F);
      margin-bottom: 8px;
    }

    .premium-data-row {
      @media screen and (max-width: $smallScreen) {
        display: revert !important;
      }
    }

    .premium-amount {
      font-family: sofiaprobold;
      font-style: normal;
      font-weight: 900;
      font-size: 20px;
      line-height: 26px;
      font-feature-settings: 'tnum' on, 'lnum' on;
      color: var(--dark-green, #10B981);
    }

    .premium-description {
      font-style: normal;
      font-size: 11px;
      line-height: 16px;
      font-feature-settings: 'tnum' on, 'lnum' on;
      color: var(--dark-gray, #3D5D6F);
    }
  }

  .coverage-plans-highlights-row {
    box-sizing: border-box;
    background: var(--white, #FFFFFF);
    border: 1px solid var(--light-blue, #CAE1FF);
    border-radius: 8px;
    padding-left: 0px;

    .coverage-plan-highlights-title {
      font-family: sofiaprobold;
      font-style: normal;
      font-weight: 900;
      font-size: 18px;
      line-height: 136.5%;
      font-feature-settings: 'tnum' on, 'lnum' on;
      color: var(--dark-gray, #3D5D6F);
      margin-top: 24px;
      margin-bottom: 37px;
      margin-left: 32px;
    }

    .green-check-icon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
      margin-left: 32px;
    }

    .highlight-table-description {
      p {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    .highlights-table-column {
      padding: 0px;
    }

    table {
      width: 100%;
    }

    tr {
      min-height: 56px;
      display: flex;
    }

    tr:nth-child(odd) {
      background: var(--off-white, #F6FAFF);
    }

    tr:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    td {
      display: inline-block;
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  .coverage-plans-theo-row {
    box-sizing: border-box;
    background: var(--white, #FFFFFF);
    border: 1px solid var(--light-blue, #CAE1FF);
    border-radius: 8px;
    min-height: 96px;

    .coverage-plans-theo {
      width: 64px;
      height: 39px;
      margin-right: 19px;
    }

    .helpful-tip-text {
      font-family: sofiaprobold;
      font-style: normal;
      font-weight: 900;
      font-size: 16px;
      line-height: 24px;
      font-feature-settings: 'tnum' on, 'lnum' on;
      color: var(--dark-blue, #0C354B);
    }

    .theo-tip-text {
      font-style: normal;
      font-size: 16px;
      line-height: 24px;
      font-feature-settings: 'tnum' on, 'lnum' on;
      color: var(--dark-blue, #0C354B);
    }
  }

  .coverage-plans-button-row {
    padding-bottom: 24px;

    .coverage-plans-toggle-details-button {
      font-family: sofiaprobold;
      font-style: normal;
      font-weight: 900;
      font-size: 16px;
      line-height: 24px;
      align-items: center;
      font-feature-settings: 'tnum' on, 'lnum' on;
      color: var(--primary-red, #FD7B56);
      background: var(--white, #FFFFFF);
      letter-spacing: normal;
      margin-right: -30px;
    }

    .coverage-details-chevron-icon {
      height: 24px;
      width: 24px;
    }

    .chevron-icon::after, .chevron-icon-down::after {
      font-family: 'mhmchevrondown', sans-serif;
      color: var(--primary-red, #FD7B56);
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      font-size: 24px;
      text-transform: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      display: inline-block;
      content: '\e900';
      vertical-align: middle;
      border: none;
    }

    .chevron-icon-down::after {
      transform: rotate(180deg);
    }
  }

  .coverage-plans-details-row-hide {
    display: none;
  }
}
