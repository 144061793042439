.confirmation-box {
  width: 288px;
  height: 293px;
  background: var(--white, #FFFFFF); //is there a reason we can't use SASS variables in these files, like here?
  border: 2px solid var(--light-gray, #E2E8F0);
  box-sizing: border-box;
  border-radius: 8px;
  margin-left: 8px;
  margin-right: 4px;
}

.confirmation-box-image {
  width: 172px;
  height: 172px;
}

.confirmation-box-title {
  width: 232px;
  height: 25px;
  font-family: sofiaproblack;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 136.5%;
  text-align: center;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: var(--dark-blue, #0C354B);
  margin-top: 8px;
}

.coverage-confirmation-title {
  font-family: sofiaproblack;
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  line-height: 135%;
  text-align: center;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: var(--dark-blue, #0C354B);
}

.coverage-confirmation-subtitle {
  font-family: sofiaproblack;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: var(--dark-gray, #3D5D6F);
}
