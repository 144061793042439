/*
  These colors must be defined as SASS variables and cannot use the CSS variables we use everywhere else.
  They're overridden using rgb variables later.
*/
$primaryRed: #FD7B56;
$gray: #FFAB93;
$errorRed: #F43F5E;
$successGreen: #20D4A9;

$theme-colors: (
  'primary': $primaryRed,
  'info': $gray,
  'danger': $errorRed,
  'success': $successGreen,
);

#root {
  overflow: hidden;
}

@import '~bootstrap/scss/bootstrap';
